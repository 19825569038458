import React from 'react';

function Banner() {
    return (
        <div>
            {/* <header> */}
            <div className='car-box' >
                <div id="hero-area" style={{margin:'0', background: 'url("/Images/CarouselImages/1000/web-dev.jpg")', height:'100%', backgroundRepeat: 'no-repeat', backgroundSize:'cover'}}>
                <div className="overlay"></div>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 col-lg-12 col-xs-12 text-center">
                            <div className="contents">
                                <h1 className="head-title"><span className="year">AVPS Technologies Inc.</span></h1>
                                {/* <p className="quotes">IT Solutions and IBM Products Implementation & Service Provider.</p> */}
                                <p>IT Solutions and IBM Products Implementation & Service Provider </p>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </div>

                {/* <img src="laptop.jpg" height="80%" width="100%"/> */}
                {/* <div className="centered text-center spacing" >
                    <div className="companyName">AVPS Technologies Inc.</div>
                    <div className="quotes">IT Solutions and IBM Products Implementation & Service Provider.</div>
                </div> */}
            {/* </header> */}
        </div>
    )
}
export default Banner;