import React from 'react';
import YouTubeVideo from './YouTubeVideo';
import '../css/products.css';
import {Helmet} from 'react-helmet';

function Product() {

    return (
        <div>
            <Helmet>
                <meta charSet="UTF-8" />
                <title>AVPS Technologies Products</title>
                <meta name="description" content="AVPS Technologies Inc. Products" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name='Ibm products' content='IBM partner: AVPS Products '/>
            </Helmet>           

            <div className="videoContainer" style={{marginTop: '8vw'}}>            
                <div className="row quotes">
                    <div className="col-sm-4">
                        <h1>Ready-made Products and Customizable for your Business.</h1>
                    </div>
                </div>
                <div className="row contentRow">
                    <div className="col-sm videobox">
                        <h3 className="text-center">AIMA</h3>
                        <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam consectetur mi magna, non semper augue volutpat eget. Quisque ac fringilla elit. Nullam finibus magna lacus, commodo luctus eros accumsan vitae. Nullam ac lacinia ipsum. Etiam fringilla vestibulum est, ut facilisis erat posuere id. Quisque aliquam massa vitae leo porta, nec dignissim tortor laoreet. Donec gravida ligula ante, vel interdum lacus ultricies eget. Aliquam nisi purus, venenatis tristique ligula vel, ornare aliquam est. Phasellus quis velit fringilla, euismod quam id, viverra nisi. Vestibulum elementum, ipsum vitae mollis eleifend, libero augue ultricies nisi, ut rutrum erat risus ac augue. Morbi vel congue est. Curabitur quis nisl nec metus pellentesque venenatis.</div>
                    </div>
                    <div className="col-sm">
                        <YouTubeVideo videoId="2g811Eo7K8U"></YouTubeVideo>
                    </div>
                </div>
                <hr />
                <div className="row contentRow">
                    <div className="col-sm">
                        <YouTubeVideo videoId="2g811Eo7K8U"></YouTubeVideo>
                    </div>
                    <div className="col-sm videobox">
                        <h3 className="text-center">ARS</h3>
                        <div>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam consectetur mi magna, non semper augue volutpat eget. Quisque ac fringilla elit. Nullam finibus magna lacus, commodo luctus eros accumsan vitae. Nullam ac lacinia ipsum. Etiam fringilla vestibulum est, ut facilisis erat posuere id. Quisque aliquam massa vitae leo porta, nec dignissim tortor laoreet. Donec gravida ligula ante, vel interdum lacus ultricies eget. Aliquam nisi purus, venenatis tristique ligula vel, ornare aliquam est. Phasellus quis velit fringilla, euismod quam id, viverra nisi. Vestibulum elementum, ipsum vitae mollis eleifend, libero augue ultricies nisi, ut rutrum erat risus ac augue. Morbi vel congue est. Curabitur quis nisl nec metus pellentesque venenatis.</div>
                    </div>
                </div>

            </div>
            
        </div>
    )
}
export default Product;