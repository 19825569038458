import React from 'react';
import '../css/footer.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import * as Icons from '@fortawesome/fontawesome-free-solid';

function AvpsFooter() {
    return (
        <div className="footer">   
                <div className="footer-column">
                    <a href="https://www.youtube.com/@AVPSTechnologiesInc" target="_blank"  name='youtube' aria-label='Youtube Icon'>
                        <i className="fab fa-youtube" aria-hidden="true" 
                        style={{ 'fontSize': '28px','color':'#C4302B' }}></i>
                    </a>
                    {/* rel='noopener'                */}
                    
                    <a href="https://www.linkedin.com/company/avpstech" name='linkedin'  target="_blank" aria-label='LinkedIn Icon' >
                        <i className="fab fa-linkedin" 
                        style={{ 'fontSize': '28px','color':'#0E76A8 ' }}></i>
                    </a>
                    {/* rel='noreferrer' */}
                    
                    <a href="https://www.facebook.com/avpstech" 
                        name='facebook' target="_blank" aria-label='Facebook Icon'>
                        <i className="fab fa-facebook" 
                        style={{ 'fontSize': '28px','color':'#3B5998' }}></i>
                    </a>
                    
                    <a href="https://twitter.com/avpstech" name='X' target="_blank" aria-label='twitter Icon'>
                        <i className="fab fa-twitter" 
                        style={{ 'fontSize': '28px','color':'#00ACEE' }}></i>
                    </a>
                
                </div>
            <p><span className="fa fa-copyright" style={{ 'fontSize': '12px', fontWeight:"bold" }}> <span>{(new Date().getFullYear())}</span></span> All Rights Reserved AVPS Technologies Inc. Minority Owned Business</p>
            <div className="footer-column">
                <a className="navbar-img" href="/" title='AVPS Technologies Inc.'>
                            <LazyLoadImage src='/Images/300/NMSDC_CERIFIED_2024.png' height='60%' width='60%' alt="Brand Icon"/>
                </a> 
           </div>
           <script type="application/ld+json">
                    {`
                        {
                            "@context": "https://schema.org",
                            "@type": "Organization",
                            "name": "AVPS Technologies Inc",
                            "description": "AVPS Technologies is a certified Minority Owned Business.",
                            "url": "https://www.avpstech.com",
                            "founder": "Vijayakumar Venugopal",
                            "foundingDate": "2017",
                            "contactPoint": {
                                "@type": "ContactPoint",
                                "telephone": "470-268-3257",
                                "email": "info@avpstech.com",
                                "contactType": "Customer Service"
                            }
                        }
                    `}
                </script>
        </div>
        
    )
}
export default AvpsFooter;