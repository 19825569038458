import React from 'react';
import CountUp from 'react-countup';
import '../css/Us.css';

function Us() {

    return (
        <div className="whoweare">
            <div className="container">
                {/* <h2 className="text-center">Who We Are</h2> */}
                <h2 className="subtitle ">who we are </h2>


                <div className="row text-center">
                    <div className="col-sm col-lg col-xl"><span className="heading">Business Domains</span>
                        <p className="count"><CountUp start={0} end={10} duration={5} suffix="+"></CountUp></p>
                    </div>
                    <div className="col-sm col-lg col-xl"><span className="heading">Years of IT Experience</span>
                        <p className="count"><CountUp start={0} end={20} duration={5} suffix="+"></CountUp></p>
                    </div>
                    <div className="col-sm col-lg col-xl"><span className="heading">Clients</span>
                        <p className="count"><CountUp start={0} end={20} duration={5} suffix="+"></CountUp></p>
                    </div>
                    <div className="col-sm col-lg col-xl"><span className="heading">Technologies</span>
                        <p className="count"><CountUp start={0} end={10} duration={5} suffix="+"></CountUp></p>
                    </div>

                </div>

            </div>
        </div>

    )

}
export default Us;