import React from 'react'
import {
    Card, 
    CardActionArea,
    CardContent,
    CardMedia,
    Button,
    Typography,
} from '@mui/material';
import { styled } from "@mui/system";
// import { Link,  } from 'react-router-dom';

const useStyles = styled({
    root: {
    //   maxWidth: 340,
      width: '100%',
    },
    typo:{
      fontSize: '20px',
      textTransform: 'capitalize',
      fontWeight: 'bold '
    },
    titleHead:{
      height: '3rem',
      padding:'2%',
      width: '16rem',
      background: 'linear-gradient(45deg, #C71585 60%, #fff 30%)',
      wordWrap:'break-word',
    //   transform: 'rotate(45deg)',
    //   borderTopRightRadius :'30%',
    //   borderBottomRightRadius: '30%',
      color: 'white',
      fontSize: '15px',
    },
    second:{
        height:'3rem',
        padding:'2%',
        width: '3rem',
        background: 'linear-gradient(45deg, #fff 55%, #00003f 30%, #00003f 10%)'
        ,  transform: 'rotate(50deg)',

    }
  });
const CustomCard=(props)=>{
    const {title, content, img, serviceUrl }=props
    const classes = useStyles()
    // console.log(props.history);
    return(
        <div>
            <Card className={classes.root} id='offering-card' >              
                <CardActionArea
                    action={Button} href={serviceUrl}  className='serviceAction offering-over'
                    >
                 <div className="img-wrapper">
                    <CardMedia
                    component="img"
                    alt={title}
                    height="200"
                    image={img}
                    title={title}
                    />
                    </div>
                     
                <CardContent view overlay hm-black-strong style={{width: '100%', maxHeight: '100%', height:'15rem'}} className='line-clamp'>
                    <h5 gutterbottom variant="h5" className={classes.typo} >
                        {title}
                    </h5>
                    <Typography variant="body2" color="textSecondary" className='text-justify wow fadeInUp bg-white' style={{fontSize: '17px'}}>
                        {content}
                    </Typography>
                </CardContent>

                </CardActionArea>
            </Card>  

        </div>
    )
}

export default CustomCard;