import React from "react";
// import '../css/shapes.css';
import '../../css/animations.css'
import '../../css/Services.css'
import '../../css/font.css'
import DoubleArrowIcon from '@mui/icons-material/DoubleArrow';
import SubdirectoryArrowRightIcon from '@mui/icons-material/SubdirectoryArrowRight';
import ContactForm from "../parts/ContactForm";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";
// const sharp = require('sharp');


function ODMService() {
    const mystyle = {
        position: 'relative',
        paddingRight: '15px',
        paddingLeft: '15px',
        width: '100%'
      };
      const imgstyle = {
        position: 'relative',
        paddingRight: '15px',
        paddingLeft: '15px',
        width: '100%',
        height: '100%'
      }
      
    return (
        <div className='upperMargin' >
            <Helmet>
                <title>AVPS ODM Services</title>
                <meta name="description" content="AVPS Technologies ODM Services" />
                <meta name='Ibm ODM services' content='IBM partner: AVPS ODM Services'/>
            </Helmet>

            <div className="page-header" style={{ background: 'url("/Images/300/IT-1.png")', backgroundSize: 'cover' }}>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="breadcrumb-wrapper text-white">
                                <p className='fontcontent serviceData' >OUR SERVICE</p>
                                <h1>IBM Operational Decision Manager </h1>
                                <ol className="breadcrumb">
                                    <li><a href="/">Home/</a><a href="/services">Services/</a>IBM ODM Services</li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className='container-fluid '>
                <div className='content-wrapper'>
                    <center>
                        <p className='h2 text-center' style={{ color: '#5c5c5c' }}>Business Decision Automation with high accuracy </p>
                        <hr className='hr2 text-center' />
                    </center>
                    <br />
                    <br />
                    <div className='row'>
                        <div className='col-md-6 col-sm-12' style={mystyle}>
                            <div className='leftImage'>
                                <div className='row'>
                                    <div className='col-md-4'>
                                        <p className='fontdisplay-4 mt-3 ' style={{ color: 'Highlight' }}>
                                            Still thinking about IBM ODM?
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-md-6 col-sm-12'>
                            <div className='subcontent-wrapper'>
                                <p className='serviceData fontcontent3 text-left fontstyl' style={{ color: 'rgb(10, 99, 255)' }} >
                                    Automate your business decisions with low-code tools for business users and real-time performance visibility for business managers
                                    </p>
                                <p className='fontcontent fontstyl'>
                                    With IBM ODM Automate your decision with business rules that are transparent, agile and governed.
                                    To stay competitive and meet ever rising customer expectations in today’s fast-moving digital world, businesses need to make better
                                    decisions, faster.
                                </p>

                            </div>

                        </div>
                    </div>
                </div>
            </div>

            <div className='container-fluid p-0'>
                <div className='container-bg1'>
                    <div className='container-fluid'>

                        <div className='row'>
                            <div className='col-md-6 col-sm-12' style={mystyle}>
                                <div className='boxpaddin h-100 w-100' >
                                    <h1 className='text-center wow fadeInUp'>Choose the right option</h1>
                                    <p className='fontstyl wow fadeInDown'>
                                        Most waste time and money gathering unactionable insights and relying on rigid applications. Businesses need
                                        advanced analytics combined with business rules to turn insights into effective, personalized, and immediate customer actions
                                        to generate measurable business results. For example, banks are looking for ways to personalize customer onboarding, drive
                                        upsell/cross-sell opportunities, and predict fraud. To make these complex decisions in real time and in context requires a
                                        digital decisioning platform with insight, decision, action and evaluation capabilities.
                                    </p>
                                </div>

                            </div>
                            <div className='col-md-6 col-sm-12 wow fadeInLeft' style={imgstyle}>
                                <LazyLoadImage src='/Images/300/services.jpg' alt='modify' height='100%' width='100%' />
                            </div>
                        </div>
                    </div>
                </div>
            {/* </div> */}

            {/* <div className='container-fluid p-0'> */}
                <div className='container-bg2'>
                    <div className='container-fluid'>
                        <div className='row'>
                            <div className='col-md-6 col-sm-12 wow fadeInRight'  style={imgstyle}>
                                <LazyLoadImage src='/Images/300/laughing-girl.jpg' height='100%' width='100%'/>
                            </div>
                            <div className='col-md-6 col-sm-12' style={mystyle}>
                                <div className='boxpaddin h-100 w-100' >
                                    <h1 className='text-center wow fadeInDown'>Our Solution</h1>
                                    <p className='fontstyl wow fadeInUp'>
                                        As the optimal solution for ensuring operational agility and creating a framework for strategic advantage,
                                        IBM Operational Decision Manager (ODM) can authorize a loan, check eligibility of business entities,
                                        determine the tiered pricing, decide on promotional offers or detect a cross-sell opportunity with high
                                        precision and personalization.
                                        
                                    </p>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

            {/* style={{width: '100%', height:'25rem', overflow: 'auto'}} */}


            <div className='container-fluid p-0' >
                <div className='avps-container'>
                    <div className='row'>
                        <div className='col-md-8 col-sm-12'>
                            <p className='h3 text-warning wow fadeInUp'>
                                IBM ODM is a comprehensive decision automation solution that helps you manage the entire lifecycle of business decisions.
                            </p>
                            <div style={{ marginLeft: '3rem' }}>
                                <p className='fontcontent wow fadeInRight' >
                                    <SubdirectoryArrowRightIcon className='text-warning' /> Collaborate to discover, model, and validate decisions using a graphical, guided experience.
                                </p>
                                <p className='fontcontent wow fadeInRight' >
                                    <SubdirectoryArrowRightIcon className='text-warning' /> Test, secure, and govern decisions within a business environment.
                                </p>
                                <p className='fontcontent wow fadeInRight' >
                                    <SubdirectoryArrowRightIcon className='text-warning' />Execute decisions with speed and consistency -- at scale.
                                </p>
                                <p className='fontcontent wow fadeInRight' >
                                    <SubdirectoryArrowRightIcon className='text-warning' />Monitor the outcomes of decisions in real time.
                                </p>
                            </div>
                        </div>

                        <div className='col-md-4 col-sm-12'>
                            <div className='wow fadeInRightBig ' style={{ width: '100%', padding: '2rem', height: 'auto', color: 'Highlight' }}>
                                <p className='h3'>
                                    Line of business users can capture, manage, automate and monitor business decisions on premises or on the cloud.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            {/* <div className='content-wrapper'> */}
            <div className='container-fluid p-0'>
                <div className='services-wrapper'>

                    <div className='service1-box'>
                        <h2 className="subtitle text-white wow bounceIn">OUR OFFERINGS</h2>
                        <div className='row'>
                            <div className='col-md-6 col-sm-12'>
                                <p className='fontcontent wow fadeInRight ' >
                                    AVPS offers 6 to 12 weeks IBM ODM Implementation services engagement to deploy the working version of the identified use case service.
                                    We work with you very closely to identify the use case, assess the functionality, dependency, complexity and come up design and
                                    continue with development and deployment.
                                    </p>

                                <p className='fontcontent wow fadeInLeft'>
                                    Outcome of our offering is to deploy the working version of initial solution implemented with IBM ODM along with mentoring your team
                                    with best practices. At the end of 6 to 12 weeks engagement period, the functional business decision service would be up and running in
                                    your environment.
                                        </p>
                                <p className='fontcontent wow fadeInRight'>
                                    Ofcourse we provide ongoing development support for additional enhancement and maintenance service based upon your need.
                                    </p>
                            </div>
                            <div className='col-md-6 col-sm-12'>
                                <div className='wow pulse' style={{ width: '100%', height: '100%' }} >
                                    <LazyLoadImage src='/Images/300/deployment.png' alt="Deployment Icon" width='100%' height='100%' />
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                 {/* highlights */}
            <div className='highlight-wrapper'>
                <div className='row'>
                    <div className='col-md-4 col-sm-12 '>
                        <h2 className='text-dark h2 wow fadeInLeftBig'>
                            HighLights :
                        </h2>
                    </div>
                    <div className='col-md-8 col-sm-12  '>
                        <p className='fontcontent wow bounceInDown'>
                            <DoubleArrowIcon className='' />  IBM stack experiences
                            </p>
                        <p className='fontcontent wow bounceInDown'>
                            <DoubleArrowIcon className='' /> Multiple domain experience with IBM Cloud Pak for Automation
                            </p>
                        <p className='fontcontent wow bounceInDown'>
                            <DoubleArrowIcon className='' /> Quick deployment
                            </p>
                        <p className='fontcontent wow bounceInDown'>
                            <DoubleArrowIcon className='' />Client enablement with best practices
                            </p>
                    </div>

                </div>
            </div>

            </div>
           

            {/* <!--   cta section start    --> */}
            <div className="cta-section home-2">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-lg-6 col-sm-12 col-md-6">
                            <h2 className="text-center reseller-font wow slideInUp">
                                We are authorized reseller of the IBM ODM Software licences required for your enterprise solution.</h2>
                        </div>
                        <div className="col-lg-6 col-sm-12 col-md-6 wow zoomIn">
                            <ContactForm title='Ready for discovery session?' />
                        </div>
                    </div>
                </div>
            </div>
            {/* <!--   cta section end    --> */}


        </div>
    )
}


export default ODMService;