import React, { Component } from 'react';
import {Button } from '@mui/base';
import Controls from './Controls';
import './contact.css'
import '../../css/font.css'
import axios from 'axios';
import Recaptcha from 'react-recaptcha';


class ContactForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            username: "",
            email: "",
            phone: "",
            message: "",
            notification:"",
            isVerified:false,
            flag: false
        }

        this.onSubscribeUser = this.onSubscribeUser.bind(this);
        this.onChange = this.onChange.bind(this);
        this.recaptchaLoaded = this.recaptchaLoaded.bind(this);
        this.verifyCallback = this.verifyCallback.bind(this);
    }
    
    recaptchaLoaded(e){
        console.log("Recaptcha loaded successfully");       
    }
    verifyCallback(e){
        console.log('Verified recaptcha!!!!');
        this.setState({
            isVerified:true
        })
    }
    onChange(e){
        this.setState({ [e.target.name]: e.target.value});
         console.log(e.target.value)
    }
    
    onSubscribeUser = (event) => {
        event.preventDefault();
        const URL = "https://www.avpstech.com/";
        const user = {
            username: this.state.username,
            email:this.state.email,
            phone:this.state.phone,
            message:this.state.message
        }
        if(this.state.isVerified){
            axios.post(URL + 'sendEmail', user)
            .then((res, err) => {
                if(err){
                    console.log(err);       
                    this.setState({
                        flag: false,
                        notification: "Error: "+ err
                    });
                }
                else{
                    this.setState({
                        flag: true,
                        notification: "Thanks for contacting us. We'll respond as soon as possible."
                    });
                    console.log(user);
                    this.setState({
                        username: "",
                        email:"",
                        phone:"",
                        message:""
                    })
                }           
            });
        }
        else {
            alert("Please verify that you are human");
        }     
       
         console.log(user);
     }

    //  handleCount(){
    //     var total=document.getElementById("2").value;
    //     total=total.replace(/\s/g, '');
    //     alert(total.length);
    //     // document.getElementById("total").innerHTML="Total Characters:"+total.length;
    //  }

   render(){
        return (
            <div>
                <div className='contactBox'>
                    <h1 className='wow fadeInUp text-center contact-title' style={{color: 'white'}}>{this.props.title}</h1>
                
                    <form onSubmit={this.onSubscribeUser}>
                    {this.state.flag ? 
                                            <p className="text-success">{this.state.notification} </p>
                                        :   <p className='text-danger' >{this.state.notification} </p>
                                        }
                        <div className='row'>
                            <div className='col-12 col-sm-12'>
                                {/* <Controls.Input 
                                    id='1'
                                    type='text'
                                    name='username'
                                    label='Name'
                                    value={this.state.username}
                                    onchange={this.onChange}
                                    /> */}
                                     <div className="form-group">
                                            <input type="text" className="form-control" name='username'
                                            value={this.state.username} onChange={this.onChange} placeholder="Name" required />
                                        </div>
                            </div>
                            <div className='col-sm-12 col-12 '>
                                {/* <Controls.Input 
                                    id='2'
                                    name='phone'
                                    label='Phone'
                                    type='tel'
                                    length="10"
                                    value={this.state.phone}
                                    onchange={this.onChange}
                                /> */}
                                <div className="form-group">
                                    <input type="tel" className="form-control" maxlength="10" pattern="[0-9]{10}$"  
                                        name='phone' value={this.state.phone} onChange={this.onChange} placeholder="Phone Number*" required />
                                </div>
                            </div>
                            <div className='col-sm-12 col-12'>
                                {/* <Controls.Input 
                                    id='3'
                                    name='email'
                                    label='Email'
                                    type='email'
                                    value={this.state.email}
                                    onchange={this.onChange}
                                /> */}
                                <div className="form-group">
                                    <input type="email" className="form-control text-lowercase" name='email' pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" 
                                    value={this.state.email} onChange={this.onChange} placeholder="Email" required />
                                </div>
                            </div>
                            <div className='col-12 col-sm-12 '>
                                <Controls.Multiline
                                    id="4"
                                    type='text'
                                    name='message'
                                    label='Message'
                                    rowsMax={3}
                                    value={this.state.message}
                                    onChange={this.onChange}
                                />
                            </div>
                            <div className='col-12 col-sm-12'>
                                <Recaptcha
                                    sitekey="6LduR-EZAAAAAALyQrkU5mW9_ynw7kpu8JWxy1Gq"
                                    render="explicit"
                                    verifyCallback={this.verifyCallback}
                                    onloadCallback={this.recaptchaLoaded}
                                />
                                <br/>                                 
                            </div>
                            <div className='col-12 col-sm-12 '>
                                    <button type="submit" className="form-control btn btn-success ">submit</button>
                            </div>
                        </div>
                    </form>

                </div> 
            </div>
        )
    }
}

export default ContactForm;


// const ContactForm = (props)=>{
//     const [success, setSuccess] = useState('');
//     const [response, setResponse] = useState(false);
//     const [error, setError] = useState('');
//     const [fieldError, setFieldError] = useState('');    
//     const [isVerified, setIsVerified] = useState('');    

    // const {values, 
    //     handleInputChange} = useForm(initialValue)   
    // const handleSubmit =(e)=>{
    //     console.log(props);     
    // }

  