import React from 'react';
import YouTube from 'react-youtube';
import '../css/products.css'

class YouTubeVideo extends React.Component {
    // _onReady(event) {
    //     // access to player in all event handlers via event.target
    //     event.target.pauseVideo();
    // }
    render() {
        const opts = {
            height: '290',
            width: '540',
            playerVars: {
                // https://developers.google.com/youtube/player_parameters
                autoplay: 0,
            },
        };
        const { videoId } = this.props;
        return (
            <div className="videsize">
                <YouTube videoId={videoId} opts={opts} onReady={this._onReady} />
            </div>

        );
    }
}
export default YouTubeVideo;