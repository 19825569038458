import React from "react";
import '../../css/font.css'
import './services.css'
import '../../css/animations.css'
import ContactForm from "../parts/ContactForm";
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import KeyboardBackspaceRoundedIcon from '@mui/icons-material/KeyboardBackspaceRounded';
import { Helmet } from "react-helmet";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const AutomationService=(props)=>{

    return(
        <div className='upperMargin' style={{fontFamily: 'sans-serif'}}>
            <Helmet>
                <title>AVPS Automation Pak Services</title>
                <meta name="description" content="AVPS Technologies Automation Pak Services" />
                <meta name='Ibm Automation Pak services' content='IBM partner: AVPS Automation Pak Services'/>
            </Helmet>
        
            <div className='car-box' >  
                <div className='pakimg-container'>

                </div>
                
                {/* <div className="page-header " style={{background: 'url("./public/Images/digital3.jpg")',height:'100%', padding:'0px', backgroundRepeat:'no-repeat' , backgroundSize:'cover', width:'100%'}}>
                    <div className='boxouter text-white' >
                        <div className='boxinner wow fadeInUp ' ><span style={{color: 'white'}}>IBM Cloud Pak for Automation</span></div>
                    </div>
                    <div className='pakimg-container'>
                        <img src='./public/Images/300/cloud-computing.png'  width='100%' height='100%' />
                      //  <img src='./public/Images/gif/cloud-pak.gif'  width='100%' height='100%' />
                    </div>
                </div> */}
            </div>
                     

            <div className='container-fluid p-0'>
                <div className='module-border-wrap2'>
                    <div className='bg-dark text-white pak-container '>
                        <p className='fontcontent2 wow fadeInUp ' style={{lineHeight: '2.5rem'}}>
                            Enterprise-ready containerized software solutions that give clients an open, faster, and more secure way to move core business applications to any cloud. 
                        </p>
                    </div>
                </div>
            </div>

            <div className='container'>
                <div className='content-container' style={{color: 'CaptionText'}}>
                    <h2 className="subtitle wow fadeInUp">IBM CLOUD PAK</h2>

                    <p className='fontcontent wow fadeInUp'>
                        <span className='h2'> IBM Cloud Pak™ for Automation (CP4A)</span> is a business automation platform with pre-integrated automation technologies and low-code tools to help you quickly develop modern applications that intelligently automate business operations at scale. It is designed, built and runs automation applications and services anywhere, on the cloud of your choice using containers on Kubernetes. As one of six IBM Cloud Paks™, Cloud Pak for Automation focuses on productivity use cases and insights into operational data. 
                    </p>
                    <p className='fontcontent wow fadeInUp'>
                        Clients use automation to increase the productivity and their user’s experiences. Also giving a break from low value tasks so that they can focus on high value work. CP4A is a pre-integrated set of essential software to quickly and easily design, develop and execute modern application with high degree of automation which can be scaled automatically. 
                    </p>
                    <p className='fontcontent wow fadeInUp'>
                        With IBM CP4A your application can be deployed on your choice of cloud where Kubernetes is supported, such as Red Hat Open shift, AWS, Azure and Google. Build and run automation application anywhere on the cloud of your choice. Clients can migrate their automated application without application changes or data migration without vendor lock-in. 
                    </p>

                </div>
            </div>
               
            <div className='container-fluid-0 text-white' style={{backgroundColor: 'rgb(16, 8, 128)', color:'white', padding: '2rem 0rem'}}>
                <div className='container'>
                    <h2 className="subtitle text-white">OUR OFFERINGS</h2>
                    <h1 className='h1' >Services</h1>
                    <p className='fontcontent wow fadeInUp' style={{color: ''}}>
                        AVPS offers 6 to 12 weeks IBM Cloud Pak implementation services engagement to deploy the working version of the identified use case service. We work with you very closely to identify the use case, assess the functionality, dependency, complexity and come up design and continue with development and deployment. 
                    </p>

                    <p className='fontcontent wow fadeInUp' style={{color: 'HighlightText'}}>
                        Outcome of our offering is to deploy the working version of initial solution implemented with Could pak for automation along with mentoring your team with best practices. At the end of 6 to 12 weeks engagement period, the functional automation service would up and running in your environment 
                    </p>
                    <div className='highlightsWrapper'>
                        <span className='fonttitle p-4 text-warning font-weight-bold text-center'>Highlights: </span>
                        <br/>
                        <div className='row pt-4 pb-4 d-flex justify-content-center'>

                            <div className='col-md-3 col-sm-12  '>
                                <div className='highlightsBox wow fadeInLeft'>
                                    <div className='iconBox'>
                                        <CheckCircleRoundedIcon color='secondary' style={{fontSize:'4rem'}}/> 
                                    </div>
                                    <p className='fontcontent ' style={{fontWeight:'700'}} >
                                        IBM Stack Experiences
                                    </p>
                                </div>
                            </div>
                            <div className='col-md-3 col-sm-12  '>
                                <div className='highlightsBox wow fadeInLeft '>
                                    <div className='iconBox'>
                                        <CheckCircleRoundedIcon color='secondary' style={{fontSize:'4rem'}}/> 
                                    </div>
                                    <p className='fontcontent ' style={{fontWeight:'700'}} >
                                        Multiple domain experience with IBM Cloud Pak for Automation 
                                    </p>
                                </div>
                            </div>
                            <div className='col-md-3 col-sm-12  '>
                                <div className='highlightsBox wow fadeInLeft'>
                                    <div className='iconBox'>
                                        <CheckCircleRoundedIcon color='secondary' style={{fontSize:'4rem'}}/> 
                                    </div>
                                    <p className='fontcontent ' style={{fontWeight:'700'}} >
                                        Quick deployment  
                                    </p>
                                </div>
                            </div>
                            <div className='col-md-3 col-sm-12  '>
                                <div className='highlightsBox wow fadeInLeft'>
                                    <div className='iconBox'>
                                        <CheckCircleRoundedIcon color='secondary' style={{fontSize:'4rem'}}/> 
                                    </div>
                                    <p className='fontcontent ' style={{fontWeight:'700'}} >
                                        Client enablement with best practices
                                    </p>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
                {/* contact form */}
              
            </div>

              {/* <!--   cta section start    --> */}
                <div className="cta-section cta-bg" style={{backgroundImage: 'url("/Images/300/shift.jpg")', opacity: '0.9'}}>
                    <div className="container">
                        <div className="cta-container">
                        <div className="row align-items-center">
                            <div className="col-lg-9 col-md-9 col-sm-12">
                                <h2 className="mb-lg-0 text-center text-white text-lg-left wow fadeInUp" style={{textTransform: 'revert', lineHeight: '3rem' , fontWeight: '600'}}>
                                    Ofcourse we provide ongoing development support for additional enhancement and maintenance service based upon your need.
                                </h2>
                            </div>
                            <div className="col-lg-3 col-md-3 col-sm-12 text-center text-lg-right wow rollIn animated">
                                <LazyLoadImage src='/Images/300/thumb.png' width='100%' height='100%'  alt='thumb' />
                            </div>
                        </div>
                        </div>
                    </div>
                    <div className="cta-overlay"></div>
                </div> 
                {/* <!--   cta section end    -->   */}
            
            <div className='container contact-bg'>
                <div className='row contentCenter ' >
                    <div className='col-md-6 col-sm-12 wow flipInY '  >                            
                        <ContactForm  title='Like to know more?' />
                    </div>
                    <div className='col-md-6 col-sm-12 '>                            
                        <div className='resellerBox wow pulse animate' data-wow-delay="300ms" data-wow-iteration='infinite' 
                        data-wow-duration='2s' >
                            <p className='fontdisplay wow fadeInLeft' style={{lineHeight: '2.5rem'}}>We are authorized reseller of the IBM Cloud Pak for Automation software licenses required for your enterprise solution.  </p>
                            <KeyboardBackspaceRoundedIcon color='secondary' style={{fontWeight:'bold', width: '2rem', fontSize:'3rem'}}/>
                        </div>
                    </div>
                </div>
            </div>
           
        </div>
    )
}

export default AutomationService;