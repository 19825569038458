import React from 'react';
import '../css/aboutus.css'
import { Grid} from '@mui/material';
import GamesIcon from '@mui/icons-material/Games';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import OwlCarousel from 'react-owl-carousel';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Helmet } from 'react-helmet';

function AboutUs() {
       
    const technology=[
        {title: 'IBM ODM (Operational Decision Manager)', icon: ''},
        {title: 'IBM Watson API for ( Image and Video Analytics)', icon: ''},
        {title: 'Cloud Pak for Automation', icon: ''},
        {title: 'Redhat Openshift cloud platform (OCP)', icon: ''},
        {title: 'Microservices development and deployment in IBM cloud',icon: ''},
        {title: 'Text to Speech, Speech to Text, IOT', icon: ''},
        {title: 'AWS SERVICE such as : AWS EC2, AWS EKS, AWS Rekognition, AWS Polly, AWS Dynamo Db ', icon: ``},        
        {title: 'CI/CD : Docker, Kubernetes, Maven, Ant ,Jenkins, WebSphere', icon:``},
        {title: 'Programming : JAVA, Node.js, Python', icon: ''},
    ]
    const products=[
        {title: 'IBM ODM (Operational Decision Manager)'},
        {title: 'IBM BPM (Business Process Manager)'},
        {title: 'IBM Watson products '},        
        {title: 'IBM Cloud Pak '},
        {title: 'Websphere'},
    ]
    const tech = [
        {title: 'Digital Transformation', icon: 'fas fa-digital-tachograph', detail: ''},
        {title: 'Business Automation', icon: 'fas fa-magic', detail: ''},
        {title: 'IT Modernization', icon: 'fas fa-memory', detail: ''},
        {title: 'Software Development Cycle', icon: 'fas fa-network-wired', detail: ''},
        {title: 'Cloud Migration', icon: 'fab fa-cloudversify', detail: ''},   
    ]
    // fas fa-business-time
    const wedo = [
        {value:'Well architected enterprise environment and solutions '},
        {value:'Deep technical assistance at any stage of the project such as initiation, architecture, development, deployment and maintenance  '},
        {value:'Support during the peak demand  '},
        {value:'Confidence with migration '},
        {value:'Guidance on setting up the center of excellence '},
        {value:'Help to Adopt modern agile development '},
        {value:'If you don’t know where to start, we help you to finish successfully  '},
    ]
    return (
        <div className='upperMargin' style={{marginTop:''}}>
            {/* about SEO */}
             <Helmet>
                <title>AVPS About Us</title>
                <meta name="description" content="About AVPS Technologies Inc." />
                <meta name='Ibm partner' content='IBM partner: About AVPS '/>
            </Helmet>

          
            <div className='car-box'>
                <div className='bannerImg'>
                    <div className='container text-center '>
                        <p className='text-white lead wow bounceInDown' >ABOUT US</p>
                        <div style={{ width: '100%'}}>
                            <h1 className='h1 wow bounceInUp'>Encourage Growth & <br/> Achieve Excellence</h1>
                        </div>
                    </div>
                </div>
            </div>
            <div className='content-wrapper'>            
                <div className="container">
                    {/* <h3 className="text-center mt-4 title text-dark ">Who We Are</h3> */}
                <h2 className="subtitle">WHO WE ARE</h2>
                    <div className='row'  >
                        <div className='col-lg-7 col-md-7 col-sm-12'>
                        <div className="text-justify" >
                                <p className='p-4 fontcontent wow fadeInUp' style={{color: 'GrayText'}}>
                                    AVPS is an IT (Information Technology) solutions and software development services provider. We are IBM ODM implementation development service provider to anyone who needs help with IBM ODM product. Regardless of the status of the project, we can help you on the IBM ODM project to automate your  business  decisions. Status of the project could be, not started, started, under development, QA (Quality Assurance), currently in production. we support at any state of the project or perform turnkey implementation. 
                                </p>
                            </div>
                        </div>
                        <div className='col-md-4 col-sm-12 '>
                            <div className='module-border-wrap '>
                                <div className="contentBox wow swing center" >
                                    <LazyLoadImage src="/Images/300/ibm-silver-partner.png" alt='IBM SILVER PARTNER' height="100%" width="100%" />
                                </div>
                            </div>                       
                        </div>
                    </div>

                </div>
            </div>
       
        
       
       <div className='container'>
           <div className='text-white bg-dark p-4'>
                <p className='lead font-weight-bolder domains text-center wow fadeInDown mb-2'>
                    We are the team with following technical expertise along with deep business domain knowledge
                </p>  
                <div className='row '>
                    {technology.map((key, index)=>(
                    <div key={index} className='col-md-4 col-sm-12 wow fadeInUp mb-2'>
                        <Grid container >
                            <Grid item xs={2}>
                                {(key.icon === '') ?< DoneAllIcon/> :  key.icon}
                            </Grid>
                            <Grid item xs={10}>
                                <p className='h6'>{key.title}</p>
                            </Grid>
                        </Grid>
                    </div>                        
                    ))}
                </div>
            </div>
       </div>
       

     
      {/* <!--   IBM PRODUCTS    -->    */}
      <div className="service-section home-3">
         <div className="container">
            <h1 className="mb-lg-0 h2 text-center text-lg-left">We are approved reseller of IBM Product License.</h1>

            <div className="services">
                  <OwlCarousel className='owl-theme' arrows={false} dots={true} loop margin={10} items={3} nav autoplay={true} >
                    {products.map((key, index)=>(
                    <div className='item' key={key}>
                     <div className="single-service wow fadeInUp border" data-wow-duration="1.5s">
                        <div className="icon-wrapper"><i className="fas fa-memory"></i></div>
                        <div className="service-txt">
                           <h4 className="service-title">{key.title}</h4>
                        </div>
                     </div>
                    </div>
                    ))}
                    </OwlCarousel>
              </div>
         </div>
      </div>
      {/* <!--   IBM PRODUCTS    -->    */}
      
                
      {/* <!--  about section start  --> */}
      <div className="about-section home-3">
         <div className="container">
            <div className="row">
               <div className="col-lg-5">
                  <div className="outer">
                     <div className="inner">
                        <div className="about-txt about-bg-3">
                           <span className="title">about us</span>
                           {/* <h2 className="subtitle">WE ARE AVPS TEAM</h2> */}
                           <h2 className="subtitle">what we do</h2>
                           <p  className='lead'>Our expertise in all of the services we provide is built on our core foundations – 
                           <ul >
                               <li style={{listStyle: 'inside' , fontSize: '1rem'}}>Consulting</li>
                               <li style={{listStyle: 'inside' , fontSize: '1rem'}}>Engineering</li>
                               <li style={{listStyle: 'inside' , fontSize: '1rem'}}>Managed Services</li>
                           </ul>
                           {/* <br/>Consulting <br/>Engineering <br/> Managed services.  */}
                           <br/><br/>From strategy roadmaps  to implementation and management.
                           <br/><br/> AVPS enables you to be successful for the long term. </p>
                           {/* <lazy src="./img/signature.png" alt=""/> */}
                        </div>
                     </div>
                  </div>
               </div>
               <div className="col-xl-5 offset-lg-1 col-lg-6">
                  <div className="about-points">
                  {tech.map((key, index)=>(
                     <div className="single-point" key={index}>
                        <div className="icon-wrapper"> <i className={key.icon}></i></div>
                        <div className="point-txt">
                           <h4>{key.title}</h4>
                           <p>We are highly committed to work with you hand to hand during all stages of your software solution</p>
                        </div>
                     </div>
                  ))}
                  </div>
               </div>
            </div>
         </div>
      </div>
      {/* <!--  about section end  --> */}

      {/* <!--  features section start  --> */}
      <div className="features-section home-2 border-top">
         <div className="container">
            <div className="row feature-content">
               <div className="col-xl-5 offset-xl-7 col-lg-6 offset-lg-6 pr-0">                    
                  <div className="features">
                     {/* <div className="single-blog">
                        <div className="blog-txt">
                            <h4 className="blog-title">We help IBM ODM and IBM Cloud Pak for Automation clients to provide the following :</h4>
                        </div>
                     </div> */}
                     <h2 className="subtitle">WHY CHOOSE US</h2>
                     <div className="feature-lists">
                        <div className="single-feature wow fadeInUp" data-wow-duration="1s">
                           <div className="feature-details">
                                {wedo.map((key, index)=>(
                                    <p className='fontcontent wow fadeInUp' key={key}>
                                        <GamesIcon style={{color:'#000034'}} />   {key.value}
                                    </p>
                                ))}
                           </div>
                        </div>                        
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      {/* <!--  features section end  --> */}
      
      {/* <!--  service section start  --> */}
      <div className="service-section">
         <div className="container">
            <h2 className="subtitle">WHAT WE EXPERIENCE</h2>
            <h4 className="mb-lg-0 h5 text-bold text-lg-left pb-4 wow fadeInRight">We are experienced with developing containerized cloud native applications based on IBM cloud pak , IBM ODM , Redhat Openshift cloud platform , AWS EKS . Implementing microservices based application.</h4>
               
            {/* <h4 className="subtitle">  </h4> */}
                <div className="service-carousel">
                    <div className='row'>
                        <div className="col-lg-4 col-md-4 col-sm-12">
                            <div className="single-service">
                            <div className="img-wrapper">
                                <LazyLoadImage src="/Images/300/cloud-computing.png" alt="cloud computing" height='100%' width='100%' />
                            </div>
                            <div className="service-txt ">
                                <h4 className="service-title">Cloud Computing</h4>
                                <p className='fontcontent wow fadeInUp' >
                                    Deploying platforms onto the cloud </p>
                            </div>
                        </div>
                        </div>
                        <div className=" col-xl-4 col-md-4 col-sm-12">
                            <div className="single-service">
                            <div className="img-wrapper">
                                <LazyLoadImage src="/Images/300/predictions.jpeg" alt="IBM ODM" width='100%' height='100%' />
                            </div>
                            <div className="service-txt">
                                <h4 className="service-title">IBM ODM</h4>
                                <p className='fontcontent wow fadeInUp ' >
                                    End-to-end development with IBM ODM on any business domains </p>
                            </div>
                        </div>
                        </div>
                        <div className=" col-xl-4 col-md-4 col-sm-12">
                            <div className="single-service">
                            <div className="img-wrapper">
                                <LazyLoadImage src="/Images/300/devops.jpg" alt="DEVOPS TOOLS" height='100%' width='100%' />
                            </div>
                            <div className="service-txt">
                                <h4 className="service-title">DEVOPS Tool</h4>
                                <p className='fontcontent wow fadeInUp' >
                                    Developing and supporting enterprise applications using DevOps tool 
                                </p>
                                {/* <p className="service-para">We offers a host of logistic management services and supply chain solutions. consectetur adipiscing elit.</p> */}
                            </div>
                        </div>
                        </div>
                    </div>
                
                </div>
            </div>
            
        </div>


      {/* <!--  service section end  --> */}

            {/* owl.video.play.html */}
    <div className='container'>
        <h2 className="subtitle">Our Expertise</h2>

        <div className="expertise">
            {/* <h3 className='title'> Our Expertise</h3> */}
            <div className="whatWeDo">
                <div className="expertiseBox">
                    <h6 className="text-left bolder">Our strongest area of expertise within the business domains using IBM ODM are, (but not limited to)</h6>
                    <ul>
                        <li>
                            Perform Eligibility such as Customer, Promotions, Warranty, Devices, Policy, Product and any business entities which are required to go through the eligibility validation.
                    </li>
                        <li>
                            Generate best rate combination within 100K promotions.
                    </li>
                        <li>
                            Offer Personalization engine to issue the personalized offers to about 50M+ customers through omni channels including POS, e-Commerce and emails.
                    </li>
                        <li>
                            Placement of Items across 100M + items.
                    </li>
                        <li>
                            Implement Rate Engine with complex and dynamic tariff rules.
                    </li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="expertise">
            <LazyLoadImage src="/Images/300/AboutUs.png" alt='Our Strategy' width='50%' height='100%' />
        </div>
    </div>

    
     
    
        </div>
    )
}
export default AboutUs;