import React from 'react';
import '../css/Services.css';
// import CountUp from 'react-countup';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as Icons from '@fortawesome/fontawesome-free-solid';
import { LazyLoadImage } from 'react-lazy-load-image-component';


function Services() {
    return (
        <div className="services">
            <div className="container">
                {/* <h2 className="text-center">What We Do</h2>  */}
                <h2 className="subtitle ">What we do</h2>

                <div className="row">
                    <div className="col-md">
                        <div className="container">
                            <div className="mb-2"> <FontAwesomeIcon icon={Icons.faSearch} size="3x" color="#7544c9" /></div>
                            <h3>Discover</h3>
                            <div className="lists wow fadeInUp">
                                We help you to discover the business rules to automate your business decision with IBM Operational Decision Manager(ODM).
                            </div>
                        </div>
                    </div>
                    <div className="col-md">
                        <div className="container">
                            <div className="mb-2"><FontAwesomeIcon icon={Icons.faCode} size="3x" color="#33de33" />
                            {/* <i className="fad fa-laptop-code"></i> */}
                            </div>
                            <h3>Develop</h3>
                            <div className="lists wow fadeInUp">
                                We assist your team to drive the application development from conceptual design to production deployment with ODM Decision Services.
                            </div>
                        </div>
                    </div>
                    <div className="col-md">
                        <div className="container">
                            <div className="mb-2">
                            <LazyLoadImage src="https://img.icons8.com/pastel-glyph/72/fab002/approved-delivery.png" height="20%" width="20%" alt='Deliver'/></div>
                            <h3>Maintain</h3>
                            <div className="lists wow fadeInUp">
                                We support the your software application maintenance and enhancement seamlessly and efficiently in all aspects.
                                </div>
                        </div>
                    </div>
                    <div className="col-md">
                        <div className="container">
                            <div className="mb-2"> <FontAwesomeIcon icon={Icons.faUsersCog} size="3x" color='#7544c9' /></div>
                            <h3>Mentor</h3>
                            <div className="lists wow fadeInUp">
                            Team Enablement is our primary goal so that you can drive the changes on your own later. We mentor your team with IBM ODM technology to use it independently
                                </div>
                        </div>
                    </div>
       
                </div>
                <div className="container">
                    <a href="/services" className='btn btn-success' aria-label="Services Column">
                        Learn More...
                    </a>
                </div>
            </div>
        </div>
    )
}
export default Services;