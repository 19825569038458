import React from 'react';
import Controls from './parts/Controls';
import { height } from '@mui/system';
// import loadable from '@loadable/component'
// const Controls = loadable(() => import('./parts/Controls'))


export default function HomeOfferings(){
    const mystyle = {
        position: 'relative',
        paddingRight: '15px',
        paddingLeft: '15px',
        width: '100%'
      };
    return (
        <main >
        {/* <!--  service section start  --> */}
        <div className="service-section">
            <div className="container">
                    <div className="service-carousel">
                        {/* <CenteredTabs /> */}

                        <div className='row'>

                            <div className="col-md-3 col-sm-12" style={mystyle}>
                                <h2 className="subtitle">our offerings</h2>
                            </div>

                            <div className="col-md-4 col-sm-12" style={mystyle}>                                
                                <Controls.CustomCard
                                    title='IBM Products Implementation '
                                    content='We provide end to end managed  application development consulting services across
                                    all domains including retail, cruise, hotel, entertainment, manufacturing, trade, finance, telecommunications with different IBM Products'
                                    img='/Images/CarouselImages/400/digital.png'
                                    serviceUrl='/services'
                                    />   
                            </div>
                            
                            <div className="col-md-4 col-sm-12" style={mystyle}>
                            {/* <div className="single-service"> */}
                                    <Controls.CustomCard
                                        title='Managed IT Services and Consulting'
                                        content='Lets help you lower TCO and improve application efficiency by providing end-to-end application development
                                        services for on-premises, hybrid cloud and multi cloud environments. Deliver high performing and customer focused 
                                        application by taking advantages of latest technologies, tools and industry standard best practices. '
                                        img='/Images/300/IT-solutions.png'
                                        serviceUrl='/manageit'
                                    />  
                                {/* </div> */}
                            </div>
                        </div>
                        
                        <div className='row'>
                            <div className="col-md-3 col-sm-12" style={mystyle}>
                            </div>
                            
                            <div className="col-md-4 col-sm-12" style={mystyle}>
                                {/* <div className="single-service"> */}
                                    <Controls.CustomCard
                                        title='Cloud Migration '
                                        content='Our cloud services for Enterprise Cloud Strategy helps create a holistic cloud approach that connects
                                        your business goals to your IT architecture.  '
                                        img='/Images/CarouselImages/400/cloud-migration.png'
                                        serviceUrl='/pak'
                                    />  
                                {/* </div> */}
                            </div>
                            
                            <div className="col-md-4 col-sm-12" style={mystyle}>
                                <Controls.CustomCard
                                    title='Application Modernization '
                                    content='Increase IT speed to maximize ROI from existing applications. Define and build application and infrastructure 
                                        strategy; modernize application with latest cloud strategy, technology and security and migrate applications to the cloud. '
                                    img='/Images/CarouselImages/400/personalization.png'
                                    serviceUrl='/app-modernization'
                                />  
                            </div>
                            
                        </div> 
                    
                    </div>
            </div>
            
        </div>

          
        </main>
    )
}