import React from "react";
import { TextField } from "@mui/material";  
import { styled } from "@mui/system";
  const useStyles = styled((theme) => ({
    root: {
      display: 'flex',
      flexWrap: 'wrap',
    },
    margin: {
      margin: theme.spacing(0.2),
      borderColor: 'blue'
    },
  }));  

const useStylesReddit = styled((theme) => ({
  root: {
    border: '1px solid #e2e2e1',
    overflow: 'hidden',
    borderRadius: 4,
    backgroundColor: '#fcfcfb',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:hover': {
      backgroundColor: '#fff',
    },
    '&$focused': {
      backgroundColor: '#fff',
     // boxShadow: `${f(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
      borderColor: theme.palette.primary.main,
    },
  },
  focused: {},
}));


function RedditTextField(props) {
  const classes = useStylesReddit();

  return <TextField InputProps={{ classes, disableUnderline: true }} {...props} />;
}

export default function Input(props){
    const classes = useStyles();
    const {name, label, value, length, type, onchange,onblur, defaultVal, id, error, ...other}  = props;

    return (
        <div>
          <RedditTextField
            id={id}
            className={classes.margin}
            variant="filled"
            name={name}
            value={value}
            helperText={error}              
            label={label}
            type={type || 'text'}
            // maxlength={length || '50'}
            defaultValue={defaultVal}
            onChange={onchange}
            onBlur={onblur}
            style={{marginTop:'1rem', width: '100%', backgroundColor:'white', borderRadius:'10px',}}
            {...other}
            required
          />

        {/* <TextField 
            id={id}
            helperText={error}
            variant='standard'
            label={label}
            defaultValue={defaultVal}
            name={name}
            value={value}
            type={type || 'text'}
            onChange={onchange}
            onBlur={onblur}
            style={{marginTop:'1rem', width: '100%', backgroundColor:'white', borderRadius:'10px',padding:'5px', margin: '4px'}}
            // onInput = {(e) =>{
            //   e.target.value = Math.max(0, parseInt(e.target.value) ).toString().slice(0,length),
            // }}
            {...other}
            required
        />   */}
        
      </div>
    )
}
