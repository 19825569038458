import CustomCard from './CustomCard';
import Input from './Input';
import Multiline from './Mulitline'

const Controls = {
    CustomCard,
    Input,
    Multiline

}

export default Controls;