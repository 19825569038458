import React from 'react';
// import $ from 'jquery';
import HorizontalSplitIcon from '@mui/icons-material/HorizontalSplit';
import '../css/AVPSHeader.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
class AVPSHeader extends React.Component {
    // componentDidMount(){
        // $(document).ready(function(){       
        //     var scroll_pos = 0;
        //     $(document).scroll(function() { 
        //         // $(document).on('scroll', ()=>{
        //         scroll_pos = $(this).scrollTop();
        //         if(scroll_pos > 210) {
        //             $("nav").css('background-color', 'white');
        //             $("nav a ").css('color', '#29058b');
        //         } else {
        //             $("nav").css('background-color', 'transparent');
        //             $("nav a").css('color', 'white');
        //         }
        //         });
        //     });
        // }

    render(){
        return (
            <div>
                
            <nav className="navbar shadow navbar-expand-lg navbar-dark  nav-trans fixed-top" style={{top:'0rem',  padding: '1rem', margin: '0'}}>
                <div className='navbar-container '>
                {/* navbar-brand */}
                    <div className='navbar-img'>
                        <a className="" href="/" title='AVPS Technologies Inc.'>
                            <LazyLoadImage src='/Images/300/logo.png' height='100%' width='100%' alt="Brand Icon" />
                        </a>
                    </div>         
                    <button className="navbar-toggler"  type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                        {/* <span className="navbar-toggler-icon" style={{color:'rgb(10, 99, 233)'}}></span> */}
                        <HorizontalSplitIcon style={{color:'rgb(10, 99, 233)'}}/>
                    </button>
                    <div className="collapse navbar-collapse right" id="navbarNavDropdown">                    
                        <ul className="navbar-nav nav-right ">
                            <li className="nav-item ">
                                <a className="nav-link" href="/">Home <span className="sr-only">(current)</span></a>
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link dropdown-toggle" href="/services" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    Services</a>
                                <div className="dropdown-menu " aria-labelledby="navbarDropdownMenuLink">
                                    <a className="dropdown-item" href="/odm">ODM Services</a>
                                    <a className="dropdown-item" href="/pak">IBM Cloud PAK</a>
                                    {/* <a className="dropdown-item" href="/watson">IBM Watson</a> */}
                                    <a className="dropdown-item" href="/manageit">Managed Service</a>
                                </div>
                            </li>
                  
                            <li className="nav-item">
                                <a className="nav-link" href="/careers">Careers</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="/about">About</a>
                            </li>
                            <li className="nav-item navbtn border">
                                <a className="navbtnlink " href="/contactus">Contact <span className="sr-only">(current)</span></a>
                            </li>
                        </ul>
                    </div>
              
                </div>
           
             
            </nav>

    
            </div>
        )

    }
    
}

export default AVPSHeader;