import React from "react";
import '../../css/font.css'
import './services.css'
import '../../css/animations.css'
import 'react-multi-carousel/lib/styles.css';
import ContactForm from "../parts/ContactForm";
import OwlCarousel from 'react-owl-carousel';
import MobileDetect from "mobile-detect";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from "react-lazy-load-image-component";


class ManagedService extends React.Component {
    static getInitialProps({ req }) {
        let userAgent;
        let deviceType;
        if (req) {
            userAgent = req.headers["user-agent"];
        } else {
            userAgent = navigator.userAgent;
        }
        const md = new MobileDetect(userAgent);
        if (md.tablet()) {
            deviceType = "tablet";
        } else if (md.mobile()) {
            deviceType = "mobile";
        } else {
            deviceType = "desktop";
        }
        return { deviceType };
    }

    state = { isMoving: false };

    render() {

    const techList = [
        { title: 'Programming', details: 'JAVA and JAVA related frameworks ', icon: 'fas fa-code' },
        { title: 'Microservices', details: 'Spring Boot, Node JS, Java Enterprise JAVA Beans (EJB) ', icon: 'fab fa-servicestack' },
        { title: 'Middleware', details: 'TOMCAT, JBOSS, Kafka Streams, Websphere ND, Websphere Liberty, MQ ', icon: 'fas fa-align-center' },
        { title: 'NoSQL', details: 'MongoDB, AWS DynamoDB ', icon: 'fab fa-aws' },
        { title: 'RDBMS', details: 'DB2, Oracle, SQL Server  ', icon: 'fas fa-database' },
        { title: 'CLOUD', details: 'RedHat Openshift, IBM Cloud,AWS, Azure, Google ', icon: 'fab fa-cloudversify' },
        { title: 'Serverless Architecture', details: 'Openshift, Knative, AWS Stack ', icon: 'fas fa-server' },
        { title: 'Front End ', details: 'React JS, Redux ,React Native, Angular JS  ', icon: 'fas fa-laptop-code' },
        { title: 'DevOps , DevSecOps', details: 'AWS Pipeline, OpenShift ', icon: 'fab fa-dev' },
        { title: 'Business Rules Management ', details: ' IBM ODM ', icon: 'fas fa-users-cog' },
        { title: 'Business Automation ', details: 'IBM Cloud Pak for Automation ', icon: 'fas fa-magic' },
    ]

    
        return (
            <div className='upperMargin'>
                <Helmet>
                    <title>AVPS Managed IT Services</title>
                    <meta name="description" content="AVPS Technologies Managed IT Services" />
                    <meta name='Ibm Managed IT services' content='IBM partner: AVPS Managed IT Services'/>
                </Helmet>

                <div className='car-box' >
                    <div className='managed-banner '>
                        <h1 className='wow fadeInRight'>Managed IT Service</h1>
                    </div>
                </div>
                <div className='container-fluid p-0'>
                    <div className='module-border-wrap2'>
                        <div className='bg-dark text-white pak-container '>
                            <p className='fontcontent2 wow bounceInLeft' style={{ lineHeight: '2.5rem' }}>
                                We help you to build reliable IT solutions with commitment and high quality. Provide any level of assistance from overseeing to turnkey implementation.
                            </p>
                        </div>
                    </div>
                </div>


                <div className='container'>
                    <h1 className='fontdisplay font-weight-bold wow fadeInUp' style={{ marginTop: '4rem', color: '#C1056F' }}>
                        Managed IT services to meet your business goals:
                    </h1>
                    <br />
                    <p className='fontcontent wow fadeInDown'>
                        We provide managed IT services to support your goals to grow your business. If you have talent or knowledge gap, we can partner with you to bridge the gap based upon your need regardless of short term or long term, help you scale your business and achieve the goals successfully.
                    </p>
                    <p className='fontcontent wow fadeInDown'>
                        We provide unique insights and approaches to modernize your existing applications or develop new applications with great speed, high confidence and reduced risk. Our subject matter experts with deep skills and historical knowledge about clients existing solutions are uniquely positioned to assist with implementation of any IT solutions.
                    </p>
                    <p className='fontcontent wow fadeInDown'>
                        We have seasoned expertise team to assist you at any stage of your software development cycle with any process you follow.
                    </p>
                </div>

                <br />

                <div className='container-fluid p-0'>
                    <div className='container'>
                        <h2 className="subtitle wow fadeInUp">key Technologies supported</h2>
                    </div>
                    <div className='keyTechBox' style={{ paddingTop: '5vw' }}>
                        <div className="container" >
                            <OwlCarousel className='owl-theme' arrows={false} dots={true} loop margin={10} items={4} nav autoplay={true} >
                                {techList.slice(0, 11).map((key, index) => (
                                    <div className="single-cta " draggable={false} key={index}>
                                        <div className="cta-icon">
                                            <i className={key.icon}></i>
                                        </div>

                                        <h4 className='font-weight-bold wow fadeInDown'>{key.title}</h4>
                                        <p className='wow fadeInDown'> {key.details}</p>
                                    </div>
                                ))}
                            </OwlCarousel>
                        </div>

                        {/* <div className="services">
                            <OwlCarousel className='owl-theme' arrows={false} dots={true} loop margin={10} items={3} nav autoplay={true} >
                            {techList.slice(0, 11).map((key, index)=>(
                                // <div className='row'>
                                    <div className='tech-supported-box '  draggable={false} key={index} >
                                        <p className='fontcontent3 font-weight-bold text-center' style={{color: '#00004d'}}>
                                            {key.title}
                                        </p>                                        
                                        <div className='techoBox '>
                                            <p className='fontcontent font-weight-bold'>
                                                {key.details}
                                            </p>
                                        </div>
                                    </div>   
                                // </div>
                                ))}
                                </OwlCarousel>
                        </div>
                    */}
                    </div>



                </div>
                <br />
                <div className='container-fluid'>
                    <h1 className='fontdisplay-4 text-center text-warning p-4 wow fadeInUp' style={{ color: '' }}>
                        Technology Stack
                    </h1>
                    <LazyLoadImage src='/Images/300/odm-stack.png' width='100%' height='100%' alt='AVPS Technology Stack' />
                </div>

                <div className='container-fluid mb-4'>
                    <div className='container'>
                        <h2 className="subtitle wow fadeInUp">our offerings</h2> 
                    </div>
                    <div className='row'>
                        <div className='col-md col-lg-6 col-sm-12 text-box text-center'>
                            <p className='fontcontent3 p-2 font-weight-bold wow fadeInLeft itp'>
                                We provide the Managed IT software services for your IT needs to help your business growth, so that you can focus on your business strategy.
                            </p>
                        </div>
                        <div className='col-md col-lg-5 col-sm-12 p-3 wow fadeInRight'>
                            <LazyLoadImage src='/Images/300/managed-it-services.jpg' height='100%' width='100%' />
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md col-sm-12 wow fadeInLeft'>
                            <LazyLoadImage src="/Images/300/sdlc.png" width='100%' height='100%' />
                        </div>
                        <div className='col-md col-sm-12'>
                            <div className='right-container'>
                                <p className='wow fadeInRight'>
                                    We can support you on any of these or all of these stages in the software development as the way you wanted.
                                </p>
                            </div>

                            {/* <div className='blur-back'>
                                    <div className='container'>
                                        <div className="modalBox">
                                           <p className='fontcontent3' style={{color: '', padding: '2rem 2rem'}}>
                                                We can support you on any of these or all of these stages in the software development as the way you wanted.                         
                                            </p>
                                        </div>
                                    </div>                                
                                </div>      */}
                            {/* <p className='fontcontent3 p-2' style={{color: '#C80874'}}> */}
                            {/* We can support you on any of these or all of these stages in the software development as the way you wanted.                          */}
                            {/* </p> */}
                        </div>
                    </div>

                </div>

                <div className='container-fluid bg-dark'>
                    <div className='row contentCenter p-5'>
                        <div className='col-md-5 col-sm-12 col-xs-12 p-0 '  >
                            <ContactForm title='Like to know more?' />
                        </div>
                    </div>
                </div>


            </div>
        )
    }
}
export default ManagedService;