import React from 'react';
//import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'bootstrap';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
require('bootstrap/dist/js/bootstrap.min.js');
// require('bootstrap/dist/css/bootstrap.css');

const domNode = document.getElementById('root');
const root = createRoot(domNode);

root.render(
  <App />
);

serviceWorker.unregister();


