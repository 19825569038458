
 
import axios from 'axios';
import React from 'react'
import '../css/careers.css'
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { Helmet } from 'react-helmet';
import $ from 'jquery'

let b ;

export default class Careers extends React.Component{
    constructor(){
        super();
        this.state={
            resume:"",
            filename:"",
            filetype:"",
            btnname:"Select",
            showfilename:"",
            // fileCheck:false
        }
    
        this.handleSave = this.handleSave.bind(this);
        this.onChangeFile = this.onChangeFile.bind(this);
        this.base64convert = this.base64convert.bind(this);
        this.onFileCheck = this.onFileCheck.bind(this);       

    }
    onFileCheck(e){
        var data = e.target.checked
        console.log(data);
        
        if(data == true){
            this.setState({
                btnname:"Upload",
            })
            document.getElementById('resume').type = 'button';
            document.getElementById('resume').onclick = this.handleSave;            
        }
        else{
            this.setState({
                btnname:"Select",
            })
            document.getElementById('resume').type = 'file';
            document.getElementById('resume').onclick = this.onChangeFile;
        }       
        
    }    
    async onChangeFile(e) {
        b  = e.target.files[0];       
        var fileurl = await this.base64convert(b);
        var resumeurl= fileurl.split(',')[1]; 
        // console.log(b.name, b.type);
        
        this.setState({
            resume: resumeurl,
            filename:b.name,
            filetype: b.type,
            showfilename:b.name,
        });
        // console.log( document.getElementById('resume').type);
    //    console.log(this.state.resume);
    }

    handleSave = (event) =>{
        const user = {
            resume: this.state.resume,
            filename: this.state.filename,
            filetype:this.state.filetype
        }
        axios.post('https://www.avpstech.com/resume',user).then((res, err) => {
                if(err){
                console.log(res.data);
                toast.success("Resume not uploaded successfully", { autoClose: 5000, position: toast.POSITION.TOP_CENTER});
        
                }
                else{
                console.log(res.data);
                toast.success("Resume uploaded successfully", { autoClose: 5000, position: toast.POSITION.TOP_CENTER});
        
                }
        })
    }    
    
    base64convert=(file)=>{
        return new Promise((resolve, reject)=>{
            var filereader = new FileReader();
            filereader.readAsDataURL(file);
            filereader.onload=()=>{
                resolve(filereader.result);
            }

        })
    }
        
    render(){
     
        return (
            <div style={{marginTop: '10vw'}}>
                <Helmet>
                    <meta charSet="UTF-8" />
                    <title>AVPS Technologies Careers</title>
                    <meta name="viewport" content="width=device-width, initial-scale=1" />
                    <meta name='Ibm career' content='IBM partner: AVPS career '/>
                </Helmet>
                
                 {/* Modal */}
                <div className="modal fade" id="basicExampleModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel"
                aria-hidden="true">
                    <div className="modal-dialog" role="document">
                        <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLabel">Please upload your resume</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="buttons-container">
                                <div className="button-effect">
                                    <label htmlFor="resume" className="effect effect-2">{this.state.btnname}</label>
                                    {/* <input type="file" id="resume" onChange={this.state.fileCheck==false? this.onChangeFile: this.handleSave} accept=".pdf,.doc"/> */}
                                    <input type="file" id="resume" onChange={this.onChangeFile} accept=".pdf,.doc" />
                                </div>
                                <span style={{'color':'green'}}>{this.state.showfilename}</span><br/>
                                <input type="checkbox" name="finalcheck" id="finalcheck" onChange={this.onFileCheck} /> <span>I am sure to upload this</span>
                            </div>
                        </div>
                        {/* <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                            <button type="button" className="btn btn-primary" onClick={this.handleSave} data-dismiss="modal">Save changes</button>
                        </div> */}
                        </div>
                    </div>
                </div>
                
                
                <div className="careersContainer">
                    <h4 className="text-center">
                        Join Our Team
                    </h4>
                    <div className="row">
                        <div className="col-md careerBox">
                            <h6>Why AVPS?</h6>
                            <div>
                                <p>
                                    If you are looking for a work place where your effort and talent are valued and make you satisfied, then apply to our job openings. Although we are small in team size, but we are big in terms of learning, trying and hard working. This is the place where you learn, you invent, you share, you mentor, and you lead and get success.
                                </p>
                                <p>
                                    <i>"Success is the result of perfection, hard work, learning from failure, loyalty and persistence ----- Colin Powell"</i>
                                </p>
                                <p>
                                    We at AVPS just provide you space and opportunity to make you succeed and it leads to us to get succeeded
                                </p>
                            </div>
                        </div>
                        <div className="col-md careerBox">
                            <h6>Core Values</h6>
                            <p>We are highly committed to deliver our values.</p>
                            <ul>
                                <li>
                                    Accountability
                                </li>
                                <li>
                                    Value to the customer
                                </li>
                                <li>
                                    Passion on what we do
                                </li>
                                <li>
                                    Strong Work Ethics
                                </li>
                            </ul>
                        </div>
                        <div className="col-md careerBox">
                            <h6>
                                Diversity
                            </h6>
                            <div>
                                <p>
                                    AVPS Technologies is an Equal Opportunity Employer. Qualified applicants will receive consideration for employment without regard to race, color, religion, sex, age, disability, military status, national origin or any other characteristic protected under federal, state, or applicable local law. We are more diversified workplace. Employees want to work with people like them because it makes them more comfortable and makes the working environment a little more pleasant.
                                </p>
                                <p>
                                    However, when diversity is introduced into the workplace, it also introduces new ways to tackle problems. The result is more productive teams.
                                </p>
                                <p>
                                    When companies recruit and retain a diverse pool of people regardless of race, religion and culture, it brings different benefits to the company as well as its employees.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="careersContainer">
                            <h3>Current openings</h3>
                            <div className="jobTitle">
                                <h4>Full Stack Developer</h4>
                            </div>
                            <div className="jobContent">
                                <p><b>Job Description</b></p>
                                <p><b>Title: Full Stack Developer</b></p>
                                <p><b>Type: Full Time</b></p>
                                <p>
                                    In AVPS ,as a Full Stack JAVA Developer, the candidate will help develop, validate, automate and create innovative quality solutions focused on clients' business needs. The Software Developer’s primary focus is on developing prototypes and examples of solutions from the customer’s perspective that will be tested at scale and in an automated fashion. The ability to understand customer pain points as a new solution is developed and tested is required. This role will assist customers remotely or on-site with application code development.
                               </p>
                                <p>
                                    This role also performs a variety of responsibilities including supporting system integration in a technology/industry specialty. This role demonstrates an understanding of a broad set of information technology tools, methods and architectures, architecture expertise in multiple application platforms, and an understanding of current or future technical applications and architectures being driven by an industry. It is desirable for the candidate to come with a background of practical application and understanding of a variety of skills such as Java development, micro-services, container-based models, Linux understanding, event-based architectures, relational databases, etc. Strong development skills, agile CI/CD knowledge, experience in development of micro-services with pluggability into multiple environments for testing purposes, and the ability to solve problems will be helpful to be effective in the role.
                               </p>
                                <p><b>Required Technical and Professional Expertise</b></p>
                                <ul>
                                    <li>Bachelor's Degree</li>
                                    <li>Development skills and agile CI/CD knowledge</li>
                                    <li>Education or development experience in JAVA and JAVA based framework technologies</li>
                                    <li>A positive attitude and willingness drive projects to completion in a fast-moving environment.</li>
                                    <li>Flexible and adaptable attitude disciplined to manage multiple responsibilities and adjust to varied environments.</li>
                                    <li>Phenomenal communicators who can explain and present concepts to technical and non-technical audiences alike, including high-level decision makers.</li>
                                    <li>Solid foundation in Computer Science, with strong competencies in data structures, algorithms and software design.</li>
                                    <li>Knowledge and experience in developing software using agile methodologies.</li>
                                    <li>Proficient in authoring, editing and presenting technical documents.</li>
                                    <li>Ability to communicate effectively via multiple channels (verbal, written, etc.) with technical and non-technical staff.</li>
                                    <li>Working knowledge of build management tools including ANT, Maven, Jenkins etc.</li>
                                    <li>UI development in modern script frameworks such as Angular or React.</li>
                                    <li>Skilled problem solvers with the desire and proven ability to create innovative solutions.</li>
                                </ul>
                                <p><b>Preferred Technical and Professional Experience</b></p>
                                <ul>
                                    <li>Master’s Degree</li>
                                    <li>Up-to 3 years strong programming experience with JAVA technologies</li>
                                    <li>Experience in or knowledge of Cloud Architecture, Social, Mobile, Analytics and Security</li>
                                    <li>GitHub experience</li>
                                    <li>Customer facing experience</li>
                                </ul>
                                
                                <button type="button" variant="outline-danger" className="btn-apply" data-toggle="modal" data-target="#basicExampleModal">
                                    Apply
                                </button>
                            </div>
    
                            <div className="jobTitle">
                                <h4>Software Engineer</h4>
                            </div>
                            <div className="jobContent">
                                <p><b>Job Description</b></p>
                                <p><b>Title: Software Engineer</b></p>
                                <p><b>Type: Full Time</b></p>
                                <p><b>Location: Alpharetta, GA</b></p>
                                <p><b>Duties</b></p>
                                <ul>
                                    <li>Develop, customize and implement enterprise level applications and solutions using Eclipses IDE, Java, IBM ODM, Rule Team Server, Rule Execution Server, Decision Validation Services, ANT, J2EE, JUnit, Oracle 11, IBM Db2, SVN, SOAP UI, Kubernetes, Minikube, Red Hat OpenShift, IBM Cloud, Jenkins, docker, Jenkins, Putty, JMeter, WebSphere Application Server, AWS DynamoDB, EKS, EC2, CloudWatch, CodeBuild.</li> 
                                    <li>Developing the different decision service to derive meta data for different tabs on the KYC Compliance Application.</li> 
                                    <li>Gather and analyze requirements.</li> 
                                    <li>Publish rule projects to Decision Center and creating the releases, change activities in Business console.</li> 
                                     <li>Fix defects based on priority.</li>
                                     <li>Work on testing and debugging.</li>
                                     <li>Other similar duties.</li>
                                </ul>
                                <p><b>Requirements:</b></p>
                                <ul>
                                    <li>MS or foreign equivalent in Computer Science or Information Technology or IS or Engineering (any) or Closely related and 6 months of experience in Java, J2EE, IBM ODM and Decision Validation Services.</li>
                                </ul>
                                {/* <div className="btn-apply">Apply</div> */}
                                <button type="button" className="btn-apply" data-toggle="modal" data-target="#basicExampleModal">
                                    Apply
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                );
        }
            
    }
 
