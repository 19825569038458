import React from 'react';
import { Carousel } from 'react-bootstrap';
import Banner from './Banner';
import '../css/Banner.css'

function Carousels() {

    return (
        <div>
            <div className='container-fluid p-0 upperMargin' >
                <Carousel> 
                    <Carousel.Item>
                        <Banner></Banner>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className='car-box '>
                            <div className='boxouter wow fadeInUp'>
                                <div className='boxinner wow fadeInUp'>IBM Product Implementation Services</div>
                            </div>
                            <div className='banner1' >   

                            </div>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className='car-box '>
                            <div className='boxouter wow fadeInUp'>
                                <div className='boxinner float-right  wow fadeInUp' >Managed IT Services and Consulting</div>
                            </div>
                            <div className='' style={{background:`linear-gradient(to left, rgba(0,0,88) 25%, transparent), url('/Images/CarouselImages/zoom.webp')`, 
                                width: '100%', height: '100%',backgroundSize:'cover', backgroundRepeat:'no-repeat'}}>
                                                               
                            </div>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className='car-box '>
                            <div className='boxouter wow fadeInUp'>
                                <div className='boxinner float-left wow fadeInUp' >Personalization 
                                    <span className="title text-white"><i className="fas fa-quote-left"></i>A Power that shines you<i className="fas fa-quote-right"></i></span>
                                </div>
                            </div>
                            <div className='float-right' style={{background:`linear-gradient(to right, #02294A 25%, transparent), url('/Images/CarouselImages/1000/personalization.png')`, 
                                width: '100%', height: '100%', backgroundSize:'contain',backgroundPosition:'right', backgroundRepeat:'no-repeat'}}>
                            </div>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className='car-box'>
                            <div className='boxouter wow fadeInUp' style={{display:'flex', justifyContent:'center'}} >
                                <div className='boxinner wow fadeInUp'style={{textAlign:'center', marginLeft:'4rem'}}>Cloud Migration</div>
                            </div>
                            <div className='img-left'>
                                <div className='centreImg'></div>
                            </div>
                            <div className='' style={{background:` url('/Images/CarouselImages/globe.gif')`, 
                                width:'100%', backgroundRepeat:'no-repeat', height:'100%',backgroundPosition:'left', opacity: '1'}}>                            
                            </div> 
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className='car-box '>
                            <div className='boxouter'>
                                <div className='boxinner float-left wow fadeInUp'>Reselling IBM Products</div>
                            </div>
                            <div className='' style={{background:`linear-gradient(to right, rgba(0, 0, 88) 55%,  transparent), url('/Images/CarouselImages/1000/reselling.png')`, 
                            backgroundRepeat:'no-repeat',backgroundSize:'contain', backgroundPosition:'right', width:'100%', height:'100%', }}>
                                <div className='img2'></div>
                            </div>
                        </div>
                    </Carousel.Item>
                    <Carousel.Item>
                        <div className='car-box '>
                            <div className='boxouter'>
                                <div className='boxinner wow fadeInUp '>Digital Transformation</div>
                            </div>
                                <div className='float-right' style={{background:`linear-gradient(to right, rgba(0, 0, 0) 30%, transparent), url('/Images/CarouselImages/still.gif')`, 
                                backgroundRepeat:'no-repeat',  backgroundSize:'cover', width:'100%', height:'100%'}}>
                                    
                                </div>
                        </div>
                    </Carousel.Item>
                </Carousel>
            </div>
            
        </div>
    )

}
export default Carousels;