import React, {  } from "react";
import { TextField } from "@mui/material";  
import { alpha } from "@mui/system";
import { styled } from "@mui/system";

const useStyles = styled((theme) => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  margin: {
    margin: theme.spacing(0.2),
    borderColor: 'blue'
  },
}));


const useStylesReddit = styled((theme) => ({
    root: {
      border: '1px solid #e2e2e1',
      overflow: 'hidden',
      borderRadius: 4,
      backgroundColor: '#fcfcfb',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      '&:hover': {
        backgroundColor: '#fff',
      },
      '&$focused': {
        backgroundColor: '#fff',
        boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
        borderColor: theme.palette.primary.main,
      },
    },
    focused: {},
  }));
  

function RedditTextField(props) {
    const classes = useStylesReddit();
  
    return <TextField InputProps={{ classes, disableUnderline: true }} {...props} />;
}

export default function Multiline(props){
    const classes = useStyles();
    const {name, label, value, length, type, onchange,onblur, defaultVal, id, error, ...other}  = props;

    return (
        <div>
         
          <RedditTextField
            multiline
            rows={3}
            className={classes.margin}
            variant="filled"
            id={id}
            helperText={error}              
            label={label}
            name={name}
            value={value}
            type={type || 'text'}
            onChange={onchange}
            onBlur={onblur}
            style={{marginBottom:'.5rem', width: '100%', backgroundColor:'white', borderRadius:'10px'}}           
            {...other}
            required
          />
      

      </div>
    )
}
