import React from 'react'
import '../css/shapes.css';
import '../css/animations.css'
import ExitToAppRoundedIcon from '@mui/icons-material/ExitToAppRounded';
import { Helmet } from 'react-helmet';
import { LazyLoadImage } from 'react-lazy-load-image-component';


const ServicesPage=(props) =>{
   
    return (
        <div className='upperMargin'>
            <Helmet>
                <title>AVPS Technologies Services</title>
                <meta name="description" content="AVPS Technologies Services" />
                <meta name='Ibm partner & AVPS services' content='IBM partner: AVPS Services'/>
           </Helmet>

            <div className='container-fluid p-0' >
                <div className='serviceImg'>
                    <div className='container' >
                        <p className='text-white lead' >OUR SERVICES</p>
                        <h1>OUR PRODUCTS & SERVICES</h1>
                    </div>
                </div>
            </div>
            <br/>

            {/* implementation service */}
            
            <div className='container' >
                {/* <div className='container'> */}
                    <h2 className="subtitle">WHO WE ARE</h2>
                {/* </div> */}

                <div className='row'>
                    <div className='col-md-4 '>
                        <div className='outerRound '>
                            <div className='customCard '>
                                <div className='roundWrapper'>
                                    <div className='roundBox'>
                                        <LazyLoadImage 
                                        src='/Images/300/odm.png' 
                                        width='50%' height='50%' 
                                        alt='Round Header' />
                                    </div> 
                                    <div className='cardcontent'>
                                        <div className='cardbody'>
                                            <h5 className='text-primary text-center'>IBM Operational Decision Manager</h5>
                                            <p className='roundText '>Automate your business decisions with low-code tools for business users and real-time performance visibility for business managers.</p>
                                        </div>
                                        <hr/>
                                        <div className='btn-box'>
                                            <a href="/odm" className="btn btn-outline-primary m-1" >Learn More</a>                                            
                                        </div>
                                    </div>
                                    
                                </div> 

                            </div>
                        </div>
                        
                    </div>
                    <div className='col-md-4'>
                        <div className='outerRound'>
                            <div className='customCard '>
                                <div className='roundWrapper '>
                                    <div className='roundBox  '>
                                        <LazyLoadImage 
                                        src='/Images/300/odm.png' 
                                        width='50%' 
                                        height='50%' alt='Round Header' />
                                    </div> 
                                    <div className='cardcontent'>
                                        <div className='cardbody'>
                                            <h5 className='text-primary text-center'>IBM Cloud Pak for Automation</h5>
                                            <p className='roundText text-norwrap'>Enterprise-ready containerized software solutions that give clients an open, faster, and more secure way to move core business applications to any cloud.</p>
                                        </div>
                                        <hr/>
                                        <div className='btn-box'>
                                            <a href="/pak" className="btn btn-outline-primary m-1" >Learn More</a>
                                        </div>
                                    </div>  
                                </div>                                                       
                            </div>
                        </div>
                        
                    </div>
                    <div className='col-md-4 ' >
                        <div className='outerRound'>
                            <div className='customCard '>
                                <div className='roundWrapper '>
                                    <div className='roundBox  '>
                                        <LazyLoadImage 
                                        src='/Images/300/odm.png' 
                                        width='50%' height='50%' 
                                        alt='Round Header' />
                                    </div> 
                                    <div className='cardcontent' >
                                        <div className='cardbody'>
                                            <h5 className='text-primary text-center'>IBM Watson</h5>
                                            <p className='roundText'>Automate your business decisions with low-code tools for business users and real-time performance visibility for business managers.</p>
                                        </div>
                                        <hr/>
                                        <div className='btn-box'>
                                            <a href="/services" className="btn btn-outline-primary m-1" >Learn More</a>
                                        </div>
                                    </div>
                                </div>                                                       
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
        

           
            <br/>

            <div className='warningBox text-warning'>
                <div className='container' >
                    <h1>NOT SURE ABOUT WHERE TO START?</h1>                    
                    <div className="button slideRight" >
                        <div className="text" type='button'>Let's go <ExitToAppRoundedIcon /></div>
                    </div>                    
                </div>
            </div>
            <br/>
            
            <br/>
            <br/>
            <br/>
            
            <div className='container-fluid '>
                {/* it service */}
                <div className='row '>
                    <div className='col-md-6 col-sm-12'>
                        <LazyLoadImage src='/Images/300/managed-it-services.jpg' width='100%' height='100%' alt="Managed It Service"/>
                    </div>
                    <div className='col-md-6 col-sm-12 ' style={{padding: '2rem'}}>
                        <p className='text-center p-3 h2'>Managed IT Services & Consulting</p>
                        <p className='lead text-center text-danger font-weight-bold'>IT Solutions and Consulting </p>
                        <p className='fontcontent' >We help you to build reliable IT solutions with commitment and high quality. Provide any level of assistance from overseeing to turnkey implementation .</p>
                    <br/>
                    <br/>
                        <center>
                            <a href='/manageIt' className='btn btn-danger btn-lg' >Show More <ExitToAppRoundedIcon  className='slideRight' /></a> 
                        </center>
                    </div>
                </div>

                <br/><br/>
                
                {/* Aplication modernization/ */}
                <div className='row '>                    
                    <div className='col-md-6 col-sm-12 ' style={{padding: '2rem'}}>
                        <p className='text-center text-danger p-3 h2'>Application Modernization</p>
                        <p className='h5 text-center font-weight-bold' style={{color: '#000045'}}>IT Solutions and Consulting </p>
                        <p className='fontcontent' >We help you to build reliable IT solutions with commitment and high quality. Provide any level of assistance from overseeing to turnkey implementation .</p>
                    <br/>
                    <br/>
                        <center>
                            <a href='/app-modernization' className='btn btn-dark btn-lg' style={{background: '#000045'}} >Show More <ExitToAppRoundedIcon  className='slideRight' /></a>
                        </center>
                    </div>
                    <div className='col-md-6 col-sm-12 p-0'>
                        <LazyLoadImage src='/Images/300/cloud-computing.png' width='100%' height='100%' alt='Cloud Computing' />
                    </div>
                </div>

                <br/>
            <br/>
            
                <div className='car-box'>
                    <h1 className='text-center' style={{color :'#000058'}}>Our Products </h1>
                    <div className='container'>
                        AIMA 

                        360 Degree – Logistics 

                        ARS 
                    </div>
                </div>
                <div className='car-box'>
                    <h1 className='text-center' style={{color :'#000058'}}>List of Videos</h1>
                    <div className='container'>
                        AIMA 

                        360 Degree – Logistics 

                        ARS 
                    </div>
                </div>
            </div>

            <br/>

            {/* <div className='container-fluid'>                
                <br/> 
                <div className='row'>
                    <div className='col-md-5 p-0'>
                        <LazyLoadImage src='/Images/digital.png'  width='100%' height='100%' />
                    </div>
                    <div className='col-md-7 p-0'>
                        <div className='h-20 p-3 mb-3 bg-primary text-white'>
                            <div className='row'>
                                <div className='col-md-2'>
                                    <LazyLoadImage src='/Images/300/odm.png' width='100%' height='100%' />
                                </div>
                                <div className='col-md-10'>
                                    <h3>IBM Operational Decision Manager</h3>
                                </div>
                            </div>                            
                            <p className='p-2 lead'>Automate your business decisions with low-code tools for business users and real-time performance visibility for business managers </p>
                        </div>
                        <div className='p-2 text-lead'>
                            Got IBM ODM (Operational Decision Manager)? Or thinking about IBM ODM? Either ways we can help you during the journey of automating your business decisions.  
                        </div>
                    </div>
                </div>            
            </div> */}
            
        </div>
        )
    
    
}
export default ServicesPage;