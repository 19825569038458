import React from "react";
import '../../css/font.css'
import './services.css'
import '../../css/animations.css'
import ContactForm from "../parts/ContactForm";
import { Helmet } from "react-helmet";
import { LazyLoadImage } from 'react-lazy-load-image-component';

const ApplicationModernization = () =>{
     
    return (
        <div>
            {/* for SEO */}
            <Helmet>
                <title>AVPS Application Modernization Services</title>
                <meta name="description" content="AVPS Technologies Application Modernization Services" />
                <meta name='Ibm cloud pak' content='IBM partner: AVPS Application Modernization Services'/>
            </Helmet>

            <div className="jumbotron p-0" >   
                <div className='app-image'>
                    <div className="container text-center text-white">
                    <i className="fas fa-quote-left"></i><span className="title text-white">Best Tech, Best Future</span><i className="fas fa-quote-right"></i>
                        <h1 className="wow bounInUp text-center" style={{ fontWeight: '700'}}>Application Modernization</h1>
                    </div>                    
                </div>
            </div>

            <div className='container' >  
                <div className='first-container'>
                    <br/>
                    <div className='row'>
                        <div className='col-md-7 col-sm-12'>
                            <p className='fontcontent wow fadeInLeft'>
                                Modernization accelerates time to value of new capabilities. It solves the problem of unpredictable application stability, Architectural complexity and outdated technologies. Modernization of core services in monolithic code base applying microservices architecture. 

                                Modernizing the existing IT infrastructure and application for digital transformation with IBM Cloud running on RedHat OPENSHIFT container platform, AWS cloud and any other cloud providers. 
                            </p>
                        </div>
                        <div className='col-md-5 col-sm-12 wow fadeInRight'>
                            <LazyLoadImage src='./public/Images/300/appm.jpeg' alt='app modernization' width='100%' height='100%' />
                        </div>
                    </div>
                
                    <br/>
                    <div className='row'>
                        <div className='col-md-7 col-sm-12 wow fadeInLeft'>
                            <LazyLoadImage src='./public/Images/300/cloud.jpeg' alt='cloud-native development' width='100%' height='100%' />
                        </div>
                        <div className='col-md-5 col-sm-12' >
                            <p className='wow fadeInRight ' style={{fontSize: '17px'}}>
                                Application modernization is driven by the need to transform business to build new capabilities and deliver them quickly and also build once and deploy on any cloud. 

                                Transform your applications and infrastructure using cloud-native development, for modernizing on any cloud. Completing a modernization assessment provides planning guides, your target architecture, including co-existence design, and an iterative value-driven execution plan. 
                            </p>

                        </div>
                    </div>
                    
                    <br/>

                    <div className='docker-container wow fadeInDown'>
                        <h2 className='text-center'>Docker & Kubernetes</h2>
                        <LazyLoadImage src='./public/Images/300/docker.jpg' alt='docker development' width='100%' height='100%' />
                    
                        <p className='fontcontent pt-4 wow fadeInUp'>
                            Microservices, containers and Kubernetes will facilitate the application cloud journey. Rely on the high security, resiliency and visibility of RedHat OpenShift technology with end-to-end testing capabilities. Accelerated development of microservices APIs with JAVA, Node.js and any other technologies implementation. 
                        </p>
                    </div>                    

                </div>
            </div>

            <br/>
            <br/>

            <div className='adv-container'>
                <div className='row'>
                    <div className='col-md-7'>
                        <LazyLoadImage src='./public/Images/300/app-development.jpg' alt='App development' width='100%' height='70%' />
                    </div>
                    <div className='col-md-4'>
                        <h1 className='text-dark'>
                            Advantages : 
                        </h1>
                        
                        <div className="row g-3 pt-4">
                            <div className="card h-100 wow fadeInRight">
                                <div className="row ">
                                    <div className="col-3">
                                        <div className="d-flex justify-content-center text-info rounded h-100" style={{fontSize: '35px'}}>
                                            <i className="fas fa-cogs align-self-center"></i>
                                        </div>
                                    </div>
                                    <div className="col-9">
                                        <h6>Increase agility and flexibility. Acceleration in time to market through agile and culture change.</h6>
                                    </div>
                                </div>                    
                            </div>
                            <div className="card h-100 wow fadeInRight">
                                <div className="row ">
                                    <div className="col-3">
                                        <div className="d-flex justify-content-center text-info rounded h-100" style={{fontSize: '35px'}}>
                                            <i className="fas fa-chart-bar align-self-center"></i>
                                        </div>
                                    </div>
                                    <div className="col-9">
                                        <h6>Reduce cost with proactive use of containerization.</h6>
                                    </div>
                                </div>  
                            </div>                        
                            <div className="card h-100 wow fadeInRight">
                                <div className="row ">
                                    <div className="col-3">
                                        <div className="d-flex justify-content-center text-info rounded h-100" style={{fontSize: '35px'}}>
                                            <i className="fas fa-layer-group align-self-center"></i>
                                        </div>
                                    </div>
                                    <div className="col-9">
                                        <h6>Testing of DevSecOps. Improvement of delivery time.</h6>
                                    </div>
                                </div>  
                            </div>
                            
                        </div>
                                                
                    </div>
                   
                </div>
            </div>


            <section className="services section-padding">
                <div className="container">
                    <div className="row">
                        <div className="col-md-4 col-sm-12">
                            <h2 className="subtitle text-capitalize">build once and deploy on any cloud</h2>                        
                        </div>
                        <div className="col-md-8 col-sm-12">
                            <div className="row">
                                <div className="col-md-6 col-lg-6 col-sm-12">
                                    <div className="services-item wow fadeInRight" data-wow-delay="0.2s">
                                        <div className="icon">
                                            <i className="fas fa-chart-bar"></i>
                                        </div>
                                        <div className="services-content">
                                            <h3><a href="">Assess your application</a></h3>
                                        <p>Identify apps that can be readily moved into the cloud platform and those that will require refactoring.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6 col-lg-6 col-xs-12">
                                    <div className="services-item wow fadeInRight" data-wow-delay="0.4s">
                                        <div className="icon">
                                            <i className="fas fa-chart-bar"></i>                            
                                        </div>
                                        <div className="services-content">
                                            <h3><a href="">RUN AND CONSISTENTLY MANAGE</a></h3>
                                            <p>Consolidate operations for all applications into a common management approach to ensure reliability and faster problem resolution.</p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6 col-lg-6 col-sm-12">
                                    <div className="services-item wow fadeInRight" data-wow-delay="0.4s">
                                        <div className="icon">
                                            <i className="fas fa-chart-bar"></i>                            
                                        </div>
                                        <div className="services-content">
                                            <h3><a href="">AUTOMATE DEPLOYMENTS FOR PRODUCTIVITY</a></h3>
                                            <p>DevOps pipelines automate deployments quickly and reliably. </p>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-6 col-lg-6 col-sm-12">
                                    <div className="services-item wow fadeInRight" data-wow-delay="0.4s">
                                        <div className="icon">
                                            <i className="fas fa-chart-bar"></i>                            
                                        </div>
                                        <div className="services-content">
                                            <h3><a href="">Build applications quickly</a></h3>
                                            <p>Leverage containers as the foundation for applications and services.</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
           
            <br/>
            <div className='container'>
                <div className='w-100 h-100 p-4'>
                    <LazyLoadImage src='./public/Images/300/cycle.png' width='100%' height='100%' />
                </div>
            </div>
            {/* <!--  about section start  --> */}
            <div className="about-section home-2">
                <div className="container">
                    <div className="row">
                    <div className="col-lg-5 col-sm-12">
                        <LazyLoadImage className='ceo-pic w-100' alt="Our Offerings" src='./public/Images/300/app-moder.png'  />
                    </div>
                    <div className="col-lg-6 col-sm-12">
                        <div className="comment-content">
                            <h2 className="subtitle">our offerings</h2>
                            <p className='fontcontent'>
                                AVPS can help you on wherever you are on your modernization process.  Whether you need to modernize your legacy apps or build cloud-native application our dedicated experts can help with industry standards and best practices. 
                            </p>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            {/* <!--  about section end  --> */}

            {/* <!--   contact us section start    --> */}
            <div className="cta-section cta-bg" style={{backgroundImage: 'url("./public/Images/1000/digital1.jpg")', backgroundAttachment: 'fixed'}}>
                <div className="container">
                    <div className="cta-container">
                    <div className="row align-items-center">
                        <div className="col-lg-9 col-md-9 col-sm-12">
                            <h1 className="mb-lg-0 h2 text-center text-lg-left wow fadeInUp">We are approved reseller of IBM Products</h1>
                        </div>
                        <div className="col-lg-3 col-md-3 col-sm-12 text-center text-lg-right">
                            <a href="/contactus" className="boxed-btn"><span>Contact Us</span></a>
                        </div>
                    </div>
                    </div>
                </div>
                <div className="cta-overlay"></div>
            </div>
      {/* <!--   contact us section end    --> */}

            <div className='container-fluid '>
                <div className='row contentCenter  p-5' >
                    <div className='col-md-5 col-sm-12 col-xs-12 col-lg-6 p-0  '  >                                                                         
                        <h1 className='fontdisplay-4 text-warning'>
                            Get In Touch :--
                        </h1>
                    </div>
                    <div className='col-md-5 col-sm-12 col-xs-12 col-lg-6 p-0  '  >                                                                         
                        <ContactForm  title='Like to know more?' />
                    </div>
                </div>
            </div>




            
        </div>
    )
}
export default ApplicationModernization;