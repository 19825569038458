import React from 'react';
// will be used
import Slider from './Carousels';
import HomeOfferings from './HomeOfferings'
import ContactForm from './parts/ContactForm';
import Us from './Us';
import Services from './Services';
import { Helmet } from 'react-helmet';

// for pixlr
// Radhe@2725
// const Carousels = React.lazy(() => import('./Carousels'));
// const HomeOfferings = React.lazy(() => import('./HomeOfferings'));
// const Services = React.lazy(() => import('./Services'));
// const Us = React.lazy(() => import('./Us'));
// const ContactForm = React.lazy(() => import('./parts/ContactForm'));

function Home() {
    return (
        <div>
            <Helmet>
                <meta charSet="UTF-8" />
                <title>AVPS Technologies Inc, IBM ODM Implementation Service provider</title>
                <meta name="description" content="AVPS Technologies Inc. Ibm Partner IBM ODM implementation provider" />
                <meta name='AVPS & IBM partners home' content='AVPS & IBM partners home' />
            </Helmet>

            <Slider></Slider>
            <HomeOfferings />
            <Services />
            <Us></Us>
           



        </div>
    )
}
export default Home;
