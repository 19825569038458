import React, { useEffect } from 'react';
import './App.css';

// import './assets/css/jquery-ui.min.css';
import './assets/css/style.css';
import './assets/css/responsive.css';

// import './assets/css/color-switcher.css';
// import './assets/css/nivo-lightbox.css';
import './assets/css/main.css';

import WOW from 'wowjs'
import { Route, Router, Routes } from 'react-router-dom'
import Home from './components/AVPSHome';
import {history} from './browser-history';
import ODMService from './components/services/ODMService'
import ServicesPage from './components/ServicesPage'
import AvpsFooter from './components/AvpsFooter'
import Careers from './components/Careers';
import AboutUs from './components/AboutUs';
import Product from './components/Products';
import AutomationService from './components/services/AutomationService';
import ContactUs from './components/ContactUs';
import ManagedService from './components/services/ManagedService';
import ApplicationModernization from './components/services/ApplicationModernization';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import AVPSHeader from './components/AVPSHeader';

function App() {
  useEffect(()=>{
    // new WOW.WOW().init();
    const wow = new WOW.WOW({
      live: false
    });
    wow.init();
  //   var wow = new WOW({
  //     live: false
  // });
  })
  return (
    <div>       
      <Router location={history.location} navigator={history}>
        <AVPSHeader></AVPSHeader>
        <Routes>
          <Route exact path="/" element={<Home/>} />
          <Route exact path="/careers" element={<Careers></Careers>} /> 
          <Route exact path="/about" element={<AboutUs></AboutUs>} />
          <Route exact path="/products" element={<Product></Product>} />
          <Route exact path="/services" element={<ServicesPage></ServicesPage>} />
          <Route exact path="/odm" element={<ODMService></ODMService>} />
          <Route exact path="/pak" element={<AutomationService></AutomationService>} />
          <Route exact path="/watson" element={<AutomationService></AutomationService>} />
          <Route exact path="/contactus" element={<ContactUs></ContactUs>} />
          <Route exact path="/manageIt" element={<ManagedService></ManagedService>} />
          <Route exact path="/app-modernization" element={<ApplicationModernization></ApplicationModernization>} />
          {/* <Route exact path="/menu" component={Menu} /> */}

        </Routes>
                 
        <AvpsFooter></AvpsFooter>
      </Router>
    </div>
  );
}

export default App;
