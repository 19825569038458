import React, { Component } from 'react';
import '../css/contactUs.css';
import axios from 'axios';
import Recaptcha from 'react-recaptcha';
import PhoneIcon from '@mui/icons-material/Phone';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { Helmet } from 'react-helmet';
import { LazyLoadImage } from 'react-lazy-load-image-component';

class ContactUs extends Component {
    constructor(props) {
        super(props)
        this.state = {
            username: "",
            email: "",
            phone: "",
            message: "",
            notification:"",
            isVerified:false,
            flag: false
        }

        this.onSubscribeUser = this.onSubscribeUser.bind(this);
        this.onChange = this.onChange.bind(this);
        this.recaptchaLoaded = this.recaptchaLoaded.bind(this);
        this.verifyCallback = this.verifyCallback.bind(this);
    }
    recaptchaLoaded(e){
        console.log("Recaptcha loaded successfully");       
    }
    verifyCallback(e){
        console.log('Verified recaptcha!!!!');
        this.setState({
            isVerified:true
        })
    }
    onChange(e){
        this.setState({ [e.target.name]: e.target.value});
    }
    
    onSubscribeUser = (event) => {
        event.preventDefault();
        const URL = "https://www.avpstech.com/";
        const user = {
            username: this.state.username,
            email:this.state.email,
            phone:this.state.phone,
            message:this.state.message
        }
        if(this.state.isVerified){
            axios.post(URL + 'sendEmail', user)
            .then((res, err) => {
                if(err){
                    console.log(err);       
                    this.setState({
                        flag: false,
                        notification: "Error: "+ err
                    });
                }
                else{
                    this.setState({
                        flag: true,
                        notification: "Thanks for contacting us. We'll respond as soon as possible."
                    });
                    console.log(user);
                    this.setState({
                        username: "",
                        email:"",
                        phone:"",
                        message:""
                    })
                }           
            });
        // alert("You are successfully subscribed");
        }
        else {
        // alert("Please fill the form properly");
        alert("Please verify that you are human");
        }
               
     }

    render(){
        const mystyle = {
            position: 'relative',
            paddingRight: '15px',
            paddingLeft: '15px',
            width: '100%'
          };
        const address = "6110 McFarland Station Drive \n Ste# 601, ";
        const city = "Alpharetta,";
        const state = "GA";
        const pincode= " 30004" ;
        return (
            <div className="contactMargin" >
                <Helmet>
                    <title>AVPS Contact Us</title>
                    <meta name="description" content="Contact at AVPS Technologies Inc." />
                    <meta name='Contact AVPS' content='IBM partner: Contact AVPS '/>
                </Helmet>
                
                <div className="contactUs ">
                    <h1 className='heading-title text-center'>Contact Us </h1>                               

                    <div className="container-fluid">
                        <div className="parent">
                            <LazyLoadImage 
                                src='/Images/contactus/georgia.jpg' 
                                alt='Office Location- Georgia' 
                                width='100%' height='100%' />

                            <div className="child">
                                <h4 className='office-location'>Georgia</h4>
                                <span className='office-country'>USA</span>
                                <LazyLoadImage 
                                src='/Images/contactus/uss.png' 
                                className='country-flag' 
                                width='100%' height='100%' 
                                alt='Country Pic' />
                                <p className='office-address'>
                                    {address}
                                    <br/>
                                    {city}
                                    <br/>
                                    {state}
                                    {pincode}
                                    {/* 6110 McFarland Station Drive
                                    Ste# 601,
                                    <br/>
                                    Alpharetta,
                                    <br/> 
                                    GA 30004 */}
                                    <br/>
                                    <small>
                                    <PhoneIcon /> +1 (470) 268-3257
                                    <br/>
                                    <MailOutlineIcon /> info@avpstech.com
                                    </small>
                                </p>
                            </div>
                        </div>
                    </div>
                
<br/>
                    
                    <div className="address-mobile-view">
                        <h4 className='office-location'>Georgia</h4>
                        <span className='office-country'>USA</span>
                        <LazyLoadImage 
                        src='/Images/contactus/uss.png' 
                        className='country-flag' 
                        width='100%' height='100%' 
                        alt='Country Pic' />
                        <p className='office-address'>
                            {address}
                            <br/>
                            {city}
                            <br/>
                            {state}
                            {pincode}
                            <br/>
                            <small>
                            <PhoneIcon /> +1 (470) 268-3257
                            <br/>
                            <MailOutlineIcon /> info@avpstech.com
                            </small>
                        </p>
                    </div>
<br/>
<br/>
                    <div className="container">                
                        <div  className="row">
                            <div  className="col-md-6 col-sm-12" style={mystyle}>                    
                                <div  className="formContainer">
                                    <div className="content wow bounceInUp">Get in touch</div>
                                    
                                    <form onSubmit={this.onSubscribeUser}>
                                        {this.state.flag ? 
                                            <p className="text-success">{this.state.notification} </p>
                                        :   <p className='text-danger' >{this.state.notification} </p>
                                        }
                                            
                                        <div className="form-group">
                                            <input type="text" className="form-control" name='username'
                                            value={this.state.username} onChange={this.onChange} placeholder="Name" required />
                                        </div>

                                        <div className="form-group">
                                            <input type="email" className="form-control text-lowercase" name='email' pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" 
                                            value={this.state.email} onChange={this.onChange} placeholder="Email" required />
                                        </div>

                                        <div className="form-group">
                                            <input type="tel" className="form-control" maxlength="10"  pattern="[0-9]{10}$"  
                                             name='phone' value={this.state.phone} onChange={this.onChange} placeholder="Phone Number" required />
                                        </div>

                                        <div className="form-group">
                                            <textarea className="form-control" name='message' required
                                            value={this.state.message} onChange={this.onChange} placeholder="Message" ></textarea>
                                        </div>
                                        
                                        <Recaptcha
                                            sitekey="6LduR-EZAAAAAALyQrkU5mW9_ynw7kpu8JWxy1Gq"
                                            render="explicit"
                                            verifyCallback={this.verifyCallback}
                                            onloadCallback={this.recaptchaLoaded}
                                        />
                                        <div className="form-group">
                                            <button type="submit" className="form-control btn btn-success " >submit</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="col-md-6 col-sm-12 align-self-center">
                                <div className="address-middle-view">
                                    <h4 className='office-location'>Georgia</h4>
                                    <span className='office-country'>USA</span>
                                    <LazyLoadImage 
                                    src='/Images/contactus/uss.png' 
                                    className='country-flag' 
                                    width='100%' height='100%' 
                                    alt='Country Pic' />
                                    <p className='office-address'>
                                        {address}
                                        <br/>
                                        {city}
                                        <br/>
                                        {state}
                                        {pincode}
                                        <br/>
                                        <small>
                                        <PhoneIcon /> +1 (470) 268-3257
                                        <br/>
                                        <MailOutlineIcon /> info@avpstech.com
                                        </small>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
        )
    }
}

        // {/* <div className="col-sm-4 contact">                    
        // <p><FontAwesomeIcon icon={Icons.faMapMarkerAlt} size="1x" style={{color:"rgb(223, 43, 43)"}} /> 6455 E Johns Xing, Suite 110, Duluth, GA 30097</p>
        // <p><FontAwesomeIcon icon={Icons.faPhone} size="1x" style={{ transform: "rotate(90deg)", color:"rgb(223, 43, 43)" }} />+1 (470) 268-3257</p>
        // <p><FontAwesomeIcon icon={Icons.faEnvelope} size="1x" style={{color:"rgb(223, 43, 43)"}} /> info@avpstech.com</p>
        // </div> */}

export default ContactUs;